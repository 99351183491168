import styled from 'styled-components';
export const AssetStyled = styled.div`
  background-image: ${({ theme }) =>
    `radial-gradient(circle at 50% 0%, ${theme.colors.gradients.a3e255b} 0%,${theme.colors.background} 100%)`};
  border: 1px solid ${({ theme }) => theme.colors.borderColor};
  box-shadow: ${({ theme }) => (theme.isDark ? '0 10px 20px 5px rgba(0, 0, 0, 0.03)' : 'none')};
  border-radius: 12px;
  padding: 0 12px;
  height: 36px;
  line-height: 34px;
  margin-right: 12px;
  cursor: pointer;
  font-size: 14px;
`;
export const AssetListStyled = styled.div`
  padding-right: 20px;
  padding-top: 10px;
  padding-bottom: 20px;
  ${({ theme }) => theme.mediaQueries.md} {
    padding-top: 0;
    padding-right: 0;
    padding-bottom: 0;
  }
  max-height: 390px;
  overflow-y: auto;
  .chain_list {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    text-align: left;
    ${({ theme }) => theme.mediaQueries.md} {
      justify-content: start;
      text-align: center;
    }

    li {
      width: 48%;
      display: flex;
      align-items: center;
      justify-content: start;
      margin-bottom: 12px;
      border-radius: 12px;
      background-color: ${({ theme }) => theme.colors.background};
      position: relative;
      padding: 1px;
      cursor: pointer;
      ${({ theme }) => theme.mediaQueries.md} {
        display: block;
        width: 31%;
        margin-right: 2.3%;
      }
      &::before {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        background: ${({ theme }) => theme.colors.gradients.violet02};
        width: 100%;
        height: 100%;
        transition: all 0.4s ease-out 0s;
        box-sizing: border-box;
        border-radius: 12px;
        visibility: hidden;
      }
      .li_inner {
        width: 100%;
        position: relative;
        z-index: 2;
        background-color: ${({ theme }) => theme.colors.background02};
        box-shadow: 0 10px 20px 5px rgba(0, 0, 0, 0.03);
        padding: 7px 12px;
        border-radius: 11px;

        display: flex;
        align-items: center;
        justify-content: start;

        ${({ theme }) => theme.mediaQueries.md} {
          display: block;
          width: auto;
          padding: 12px 0;
        }
      }
      img {
        width: 24px;
        margin-right: 8px;
        border-radius: 50%;
        ${({ theme }) => theme.mediaQueries.md} {
          display: block;
          margin: 0 auto;
        }
      }
      p {
        font-size: 14px;
        transition: all 0.3s ease;
        color: #6a6991;
        ${({ theme }) => theme.mediaQueries.md} {
          margin-top: 8px;
        }
      }
      &.on {
        background-image: linear-gradient(90deg, rgb(164 40 208 / 45%) 0%, rgb(32 212 169 / 45%) 100%);
        .li_inner {
          background-image: ${({ theme }) =>
            theme.isDark
              ? 'linear-gradient(90deg, #A428D0 0%, #20D4A9 100%)'
              : 'linear-gradient(180deg, #f4eff6 0%, #ffffff 100%)'};
          box-shadow: 0 10px 20px 5px rgba(0, 0, 0, 0.03);
        }
        &::before {
          visibility: ${({ theme }) => (theme.isDark ? 'hidden' : 'visible')};
        }
        p {
          color: ${({ theme }) => theme.colors.text};
        }
      }
      &:hover {
        p {
          color: ${({ theme }) => theme.colors.text};
        }
      }
    }
  }

  .token_list {
    li {
      display: flex;
      align-items: center;
      justify-content: space-between;
      background-color: ${({ theme }) => (theme.isDark ? theme.colors.background : theme.colors.background02)};
      box-shadow: ${({ theme }) => (theme.isDark ? '0 10px 20px 5px rgba(0, 0, 0, 0.03)' : 'none')};
      border-radius: 12px;
      margin-bottom: 12px;
      padding: 10px 16px 10px 10px;
      ${({ theme }) => theme.mediaQueries.md} {
        padding: 10px;
      }
      .icon {
        width: 20px;
        height: 20px;
        &.grey {
          path {
            fill: #6a6991;
          }
        }
      }
      button {
        padding: 8px 12px 7px;
        &:disabled {
          background: transparent;
          border: 1px solid ${({ theme }) => theme.colors.borderColor};
        }
      }
      .fl {
        display: flex;
        align-items: center;
        justify-content: start;
        p {
          // margin-left: -20px;
          color: #6a6991;
          font-weight: 600;
          font-size: 14px;
        }
        div div {
          // width: 100%;
          width: 20px;
          margin-right: 8px;
        }
        & > div {
          background-color: ${({ theme }) => theme.colors.background02};
          border-radius: 10px;
          height: 36px;
          line-height: 36px;
          padding: 0 8px;
          margin-right: 16px;
        }
      }
    }
  }
`;

export const ContractAccountInfoStyled = styled.div`
  display: flex;
  align-items: center;
  justify-content: start;
  padding: 12px 0 16px 10px;
  img {
    width: 28px;
    height: 28px;
    border-radius: 50%;
    margin-right: 12px;
  }
  h5 {
    height: 20px;
    line-height: 20px;
    font-size: 14px;
    ${({ theme }) => theme.mediaQueries.md} {
      font-size: 16px;
    }
  }

  a {
    display: flex;
    align-items: center;
    justify-content: start;
    padding-top: 4px;
    p {
      color: #6a6991;
      font-size: 12px;
      transition: all 0.3s ease;
    }
    svg {
      width: 14px;
      margin-top: -4px;
      margin-left: 4px;
      path {
        transition: all 0.3s ease;
      }
    }
    &:hover {
      p {
        color: ${({ theme }) => theme.colors.text};
      }
      svg {
        path {
          stroke: ${({ theme }) => theme.colors.text};
        }
      }
    }
  }
`;
