import { STATIC_BASE_URL } from 'config';

export const getSafeProxyFactoryContractAddress = async () => {
  try {
    const apiUrl = `${STATIC_BASE_URL}/api/safe_contract/safe_proxy_factory.json`;
    const r = await fetch(apiUrl);
    const body = await r.json();
    return body;
  } catch (e: any) {
    return {};
  }
};

export const useSafeContractStateAddress = async () => {
  try {
    const apiUrl = `${STATIC_BASE_URL}/api/safe_contract/safe.json`;
    const r = await fetch(apiUrl);
    const body = await r.json();
    return body;
  } catch (e: any) {
    return {};
  }
};
