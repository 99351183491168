import styled, { keyframes } from 'styled-components';

const ProcessIcon = ({ className }: { className?: string }) => {
  return <ProcessIconStyled className={className}></ProcessIconStyled>;
};

const loading = keyframes`
0% {
  -webkit-transform: rotate(0deg);
  transform: rotate(0deg);
}
100% {
  -webkit-transform: rotate(360deg);
  transform: rotate(360deg);
}
`;
const ProcessIconStyled = styled.div`
  font-size: 10px;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background: ${({ theme }) => theme.colors.green};
  background: ${({ theme }) => `linear-gradient(to right, ${theme.colors.green} 10%, rgba(255, 255, 255, 0) 80%)`};
  position: relative;
  animation: ${loading} 1.4s infinite linear;
  transform: translateZ(0);
  &:before {
    width: 100%;
    height: 50%;
    background: ${({ theme }) => theme.colors.green};
    border-radius: 10px 10px 0 0;
    position: absolute;
    top: 0;
    left: 0;
    content: '';
  }
  &:after {
    background: ${({ theme }) => theme.colors.background};
    width: 65%;
    height: 65%;
    border-radius: 50%;
    content: '';
    margin: auto;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
  }
`;
export default ProcessIcon;
