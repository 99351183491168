const VaultWithdrawBtnIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
      <path
        d="M15,0 C16.8409492,0 18.3333333,1.49238417 18.3333333,3.33333333 L18.3333333,13.3333333 C18.3333333,15.1742825 16.8409492,16.6666667 15,16.6666667 L10,16.6666667 C8.15905083,16.6666667 6.66666667,15.1742825 6.66666667,13.3333333 L6.66666667,10.8333333 L8.33333333,10.8333333 L8.33333333,13.3333333 C8.33333333,14.2119682 9.01323149,14.9318043 9.87561471,14.9954286 L10,15 L15,15 C15.8786348,15 16.5984709,14.3201018 16.6620952,13.4577186 L16.6666667,13.3333333 L16.6666667,3.33333333 C16.6666667,2.4546985 15.9867685,1.7348624 15.1243853,1.67123811 L15,1.66666667 L10,1.66666667 C9.12136517,1.66666667 8.40152907,2.34656483 8.33790478,3.20894805 L8.33333333,3.33333333 L8.33333333,5.83333333 L6.66666667,5.83333333 L6.66666667,3.33333333 C6.66666667,1.49238417 8.15905083,0 10,0 L15,0 Z M5,5 L4.99966667,7.5 L12.5,7.5 C12.9602373,7.5 13.3333333,7.87309604 13.3333333,8.33333333 C13.3333333,8.79357062 12.9602373,9.16666667 12.5,9.16666667 L4.99966667,9.166 L5,11.6666667 L1.66666667,8.33333333 L5,5 Z"
        fill="#CC64F2"
        fillRule="nonzero"
        transform="rotate(90 10 8.333333)"
      />
    </svg>
  );
};
export default VaultWithdrawBtnIcon;
