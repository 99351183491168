import { createSlice } from '@reduxjs/toolkit';
import { IUserState } from './types';

const userInit: IUserState = {
  isDark: true,
};
export const userSlice = createSlice({
  name: 'user',
  initialState: userInit,
  reducers: {
    toggleThemeAction: (state) => {
      state.isDark = !state.isDark;
    },
  },
});

export const { toggleThemeAction } = userSlice.actions;
export default userSlice.reducer;
