import { CHAINID } from 'config/constants/chain_config';
import Chain from './chain.json';
import { IChainItemType } from './IChainType';
export const getWalletConfig = (chainId: CHAINID | string): IChainItemType => {
  const chainObj = Chain.chains.filter((v) => `${v.id}` === chainId).map((v) => ({ ...v, id: `${v.id}` }));
  if (chainObj) {
    return chainObj[0] as unknown as IChainItemType;
  }
  return Chain.chains[0] as unknown as IChainItemType;
};
export const CHAINLISTID = [
  CHAINID.OPTIMISTIC_MAINNET,
  CHAINID.ARBITRUM_MAINNET,
  CHAINID.BSC_MAINNET,
  CHAINID.POLYGON_MAINNET,
];

export const CHAINLIST = CHAINLISTID.map(
  (vv) => Chain.chains.filter((v) => `${v.id}` === vv)[0],
) as unknown as IChainItemType[];
