import { createAsyncThunk } from '@reduxjs/toolkit';
import BigNumber from 'bignumber.js';
import { CHAINID } from 'config/constants/chain_config';
import { IMultiToken, IMultiTokenItem, VaultMatadateType, VaultState } from 'state/types';
import { BIG_TEN, BIG_ZERO } from 'utils/bigNumber';
import { IVaultAccountBalanceInfoItem } from 'views/VaultMulti/components/MultiVaultAccountBalanceInfo/MultiVaultAccountBalanceInfoItem';
import fetchTradeConfig from './fetchTradeConfig';
import fetchVaultBaseConfig from './fetchVaultBaseConfig';
import fetchVaultMultiToken from './fetchVaultMultiToken';
import { fetchVaultMultiTokenBalance } from './fetchVaultMultiTokenBalance';
import fetchVaultsV2 from './fetchVaultsV2';
import { fetchMultiUserPendingStargate } from './multi/fetchMultiUserPendingStargate';
import { fetchMultiUserVaultsBalances } from './multi/fetchVaultUserMulti';
import { IVault } from './types';
import { fetchApy, INetValueKeyItemItem } from './multi/fetchApy';
import { fetchToken } from 'views/Trade/hooks/get/fetchToken';

export const fetchVaultBaseConfigAsync = createAsyncThunk('vault/fetchVaultBaseConfigAsync', async () => {
  const vaultsConfig = await fetchVaultBaseConfig();
  return vaultsConfig;
});
export const fetchVaultMultiTokenAsync = createAsyncThunk('vault/fetchVaultMultiTokenAsync', async () => {
  const multiTokenConfig = await fetchVaultMultiToken();
  return multiTokenConfig;
});
export const fetchTradeConfigAsync = createAsyncThunk('vault/fetchTradeConfigAsync', async () => {
  const tradeConfig = await fetchTradeConfig();
  return tradeConfig;
});

export const fetchVaultMultiTokenBalanceAsync = createAsyncThunk<
  IMultiToken,
  { multiTokenConfig: IMultiToken; safeAddress: Record<string, Record<string, string>>; account: string }
>('vault/fetchVaultMultiTokenBalanceAsync', async ({ multiTokenConfig, account, safeAddress }) => {
  let multiToken = {};
  const chainList = Object.keys(multiTokenConfig);
  const result = await Promise.all(
    chainList.map((chain) => {
      const chainkey = chain as CHAINID;
      const tokenListChain = multiTokenConfig[chainkey];
      const otherAccount = safeAddress[account][chainkey];
      return fetchVaultMultiTokenBalance(tokenListChain, [account, otherAccount], chainkey);
    }),
  ).catch((e) => {});
  chainList.map((chain: string, index: number) => {
    const value = result[index];
    const chainkey = chain as CHAINID;
    let tokenListChain = multiTokenConfig[chainkey];

    const otherAccount = safeAddress[account][chainkey];
    if ('balanceOfChild' in value && 'balanceOfParent' in value) {
      const { balanceOfChild, balanceOfParent } = value;
      tokenListChain = tokenListChain.map((v: IMultiTokenItem, index: number) => ({
        ...v,
        balances: {
          [account.toLowerCase()]: balanceOfParent[index],
          [otherAccount.toLowerCase()]: balanceOfChild[index],
        },
      }));
    }
    multiToken = {
      ...multiToken,
      [chain]: tokenListChain,
    };
    return multiToken;
  });
  return multiToken as IMultiToken;
});

export const fetchVaultsPublicDataAsync = createAsyncThunk<
  [VaultMatadateType, string],
  {
    priceVsBusdMapMulti: Record<string, string>;
    vaultsData: VaultMatadateType;
  }
>('vault/fetchVaultsPublicDataAsync', async ({ vaultsData }) => {
  const vaults = await fetchVaultsV2(vaultsData);
  return vaults;
});
// export const fetchChangeChainAndDappVaultsPublicDataAsync = createAsyncThunk<
//   [VaultMatadateType, string],
//   {
//     chosedChainAndDApp: ChosedChainAndDAppType,
//     vaultsConfig
//     currentBlock: number;
//     priceVsBusdMap: Record<string, string>;
//     vaultsData: VaultMatadateType;
//   }
//   >('vault/fetchChangeChainAndDappVaultsPublicDataAsync', async ({
//     chosedChainAndDApp,  currentBlock, priceVsBusdMap, vaultsData }) => {
//   const vaults = await fetchVaultsV2(currentBlock, priceVsBusdMap, vaultsData);
//   return vaults;
// });

export const fetchMultiVaultFarmUserDataAsync = createAsyncThunk<
  [IVaultAccountBalanceInfoItem[]],
  {
    account: string;
    vaults: VaultMatadateType;
    safeAddress: Record<string, Record<string, string>>;
  }
>('vault/fetchMultiVaultFarmUserDataAsync', async ({ account, vaults, safeAddress }) => {
  // >('vault/fetchMultiVaultFarmUserDataAsync', async ({ account: _, vaults, safeAddress }) => {
  try {
    // const account = '0x41BA3387E1a5a592E27B9EE33935957CE5F872C1';
    const userVaultBalances = await fetchMultiUserVaultsBalances(account, safeAddress, vaults);
    const userLpStakingClaimBalances = await fetchMultiUserPendingStargate(account, safeAddress, vaults);
    // {
    //   chain: {
    //     "dappname": {
    //       key: value
    //     }
    //   }
    // }
    const accountBalanceInfo: IVaultAccountBalanceInfoItem[] = [];
    for (const _chain of Object.keys(vaults)) {
      const chain = _chain as CHAINID;
      const chainObj = vaults[chain];
      for (let dappI = 0; dappI < chainObj.dapp.length; dappI++) {
        const dappname = chainObj.dapp[dappI].dappname;
        const balanceOf = userVaultBalances[chain][dappname];

        // for (let i = 0; i < chainObj.dapp[dappI].contract.length; i++) {
        for (let i = 0; i < 3; i++) {
          // const item = chainObj.dapp[dappI].contract[i];
          if (!Object.values(balanceOf)[i]) {
            continue;
          }
          const isbalance = (Object.values(balanceOf)[i] as string[]).flat(2).filter((v) => Number(v) > 0).length > 0;
          if (isbalance) {
            const balanceAccount = Object.keys(balanceOf);
            for (let _ii = 0; _ii < balanceAccount.length; _ii++) {
              for (let jj = 0; jj < chainObj.dapp[dappI].contract.length; jj++) {
                let _balanceValue = balanceOf[balanceAccount[_ii]][jj]; // final balanceof number
                if (new BigNumber(_balanceValue).gt(BIG_ZERO)) {
                  const item = chainObj.dapp[dappI].contract[jj];
                  if (item.stake) {
                    _balanceValue = new BigNumber(_balanceValue)
                      .times(item.stake.amountLPtoLD)
                      .div(item.stake.amountLPtoLDFromNumber)
                      // .div(BIG_TEN.pow(new BigNumber(item.stake.wantaddressdecimals)))
                      .toString();
                  }
                  const _wantaddresssymbol = item.vault ? item.vault.wantaddresssymbol : item.stake.wantaddresssymbol;
                  const _wantaddress = item.vault ? item.vault.wantaddress : item.stake.wantaddress;
                  const _decimals = item.vault ? item.vault.decimals : item.stake.wantaddressdecimals;
                  const tokenC = await fetchToken(item.chainkey, _wantaddress, false);

                  const withdrawAbleUSD = Number(
                    new BigNumber(_balanceValue)
                      .div(BIG_TEN.pow(new BigNumber(_decimals)))
                      .times(Number(tokenC.priceUSD))
                      .toFixed(4, BigNumber.ROUND_DOWN),
                  ).toLocaleString('en-US', {
                    maximumFractionDigits: 4,
                  });
                  if (withdrawAbleUSD !== '0') {
                    if (
                      accountBalanceInfo.filter(
                        (v) =>
                          v.chain === chain &&
                          v.contractAddress === item.contractAddress &&
                          v.account === balanceAccount[_ii],
                      ).length
                    ) {
                      break;
                    }
                    const _symbol = item.vault ? item.vault.vaultsymbol : item.stake.stakesymbol;
                    let _apy = item.vault ? item.vault.total_apy : item.stake.total_apy;
                    if (_apy === '-') {
                      const apyArr = await fetchApy();
                      const _apyItem: INetValueKeyItemItem = apyArr[chain][item.contractAddress.toLowerCase()];
                      _apy = _apyItem.apy ? new BigNumber(_apyItem.apy).toFixed(2) : '-';
                    }
                    const __balanceitem: IVaultAccountBalanceInfoItem = {
                      vaulttype: item.vaulttype,
                      vaultSymbol: _symbol,
                      chain: chain,
                      dappname: dappname,
                      withdrawAble: _balanceValue,
                      vaultDecimals: _decimals,
                      withdrawAbleUSD,
                      wantaddress: _wantaddress,
                      wantaddresssymbol: _wantaddresssymbol,
                      // token0address: item.vault.token0address,
                      // token1address: item.vault.token1address,
                      contractAddress: item.contractAddress,
                      apy: _apy,
                      account: balanceAccount[_ii],
                      isStaking: false,
                    };
                    if (item.stake && userLpStakingClaimBalances) {
                      const stakingBalanceOf = userLpStakingClaimBalances[chain][dappname];
                      const claimBalanceValue = stakingBalanceOf[balanceAccount[_ii]][jj]; // final balanceof number
                      const _claimBalanceValue = Number(
                        new BigNumber(claimBalanceValue ?? '0')
                          .div(BIG_TEN.pow(new BigNumber(item.stake.earnedaddresssymbol[0]?.decimals ?? 18)))
                          .toFixed(6, BigNumber.ROUND_DOWN),
                      ).toLocaleString('en-US', {
                        maximumFractionDigits: 6,
                      });

                      const isClaimAble = new BigNumber(_claimBalanceValue).gt(BIG_ZERO);
                      __balanceitem.rewardTokenAmountNum = claimBalanceValue;
                      __balanceitem.rewardTokenAmount = _claimBalanceValue;
                      __balanceitem.isClaimAble = isClaimAble;
                      __balanceitem.isStaking = true;
                      __balanceitem.earnedaddresssymbol = item.stake.earnedaddresssymbol[0];
                    }
                    accountBalanceInfo.push(__balanceitem);
                  }
                } else {
                }
              }
            }
          }
        }
      }
    }
    return [accountBalanceInfo];
  } catch (e: any) {
    console.error('fetchMultiVaultFarmUserDataAsync: ', e);
    console.info('fetchMultiVaultFarmUserDataAsync: ', e);
    throw new Error(e?.message);
  }
});

// export const fetchVaultFarmUserDataAsync = createAsyncThunk<
//   {
//     userVaultsFarmAllowances: any;
//     userVaultsFarmTokenBalances: any;
//     userVaultUsers: any;
//   },
//   {
//     account: string;
//     vaults: VaultMatadateType;
//     index?: number;
//     chainkeyFromSource?: CHAINID;
//     dappFromSource?: string;
//     safeAddress: Record<string, Record<string, string>>;
//   }
// >(
//   'vault/fetchVaultFarmUserDataAsync',
//   async ({ account, vaults, index, chainkeyFromSource, dappFromSource, safeAddress }) => {
//     let dappIndex = null;
//     if (dappFromSource) {
//       dappIndex = vaults[chainkeyFromSource].dapp.map((v) => v.dappname).indexOf(dappFromSource);
//     }
//     // todo
//     const userVaultsFarmAllowances = await fetchVaultsFarmUserAllowances(
//       account,
//       vaults,
//       index,
//       chainkeyFromSource,
//       dappIndex,
//     );
//     const userVaultsFarmTokenBalances = await fetchVaultsFarmUserTokenBalances(
//       account,
//       safeAddress,
//       vaults,
//       index,
//       chainkeyFromSource,
//       dappIndex,
//     );
//     const userVaultUsers = await fetchVaultsUsersV2(account, vaults, index, chainkeyFromSource, dappIndex);
//     return {
//       userVaultsFarmAllowances,
//       userVaultsFarmTokenBalances,
//       userVaultUsers,
//     };
//   },
// );
const vaultExtraReducers = (builder) => {
  builder.addCase(fetchVaultBaseConfigAsync.fulfilled, (state: VaultState, action) => {
    state.vaultsConfig = action.payload;
    // state.chosedChainAndDApp = {
    //   chain: Object.keys(action.payload).map(v=>v as CHAINID) ,
    //   dapp: Object.values(action.payload)["dapp"].map(v => v.dappname)
    // };
    state.chosedChainAndDApp = Object.keys(state.vaultsConfig).map((v) => {
      return {
        chain: v as CHAINID,
        dapp: state.vaultsConfig[v].dapp.map((vv) => vv.dappname),
      };
    });
    state.chosedData = state.vaultsConfig;
  });
  // builder.addCase(fetchVaultMultiTokenAsync.pending, (state: VaultState, action) => {
  //   // state.fetchVaultMultiTokenAsyncLoading = true;
  // });
  builder.addCase(fetchVaultMultiTokenAsync.fulfilled, (state: VaultState, action) => {
    // state.fetchVaultMultiTokenAsyncLoading = false;
    state.multiTokenConfig = action.payload;
  });
  builder.addCase(fetchTradeConfigAsync.fulfilled, (state: VaultState, action) => {
    state.tradeConfig = action.payload;
  });

  builder.addCase(fetchVaultsPublicDataAsync.fulfilled, (state: VaultState, action) => {
    if (action.payload) {
      state.userDataLoaded = true;
      state.chosedData = action.payload[0];
      state.vaultsConfig = action.payload[0];
      state.tvlTotal = action.payload[1];
    }
  });
  // builder.addCase(fetchVaultMultiTokenBalanceAsync.pending, (state: VaultState, action) => {
  //   state.fetchVaultMultiTokenBalanceAsyncLoading = true;
  // });
  builder.addCase(fetchVaultMultiTokenBalanceAsync.fulfilled, (state: VaultState, action) => {
    // state.fetchVaultMultiTokenBalanceAsyncLoading = false;
    if (action.payload && Object.keys(action.payload)) {
      state.multiToken = action.payload;
    }
  });
  builder.addCase(fetchMultiVaultFarmUserDataAsync.fulfilled, (state: VaultState, action) => {
    // state.chosedData = action.payload[0];
    if (action.payload) {
      const accountBalanceInfo: IVaultAccountBalanceInfoItem[] = action.payload[0];
      const vaults = state.chosedData;
      const _balance = accountBalanceInfo || [];
      const _vaultData = {} as VaultMatadateType;
      for (const _chain of Object.keys(vaults)) {
        const chain = _chain as CHAINID;
        const chainObj = vaults[chain];
        for (let dappI = 0; dappI < chainObj.dapp.length; dappI++) {
          const dappname = chainObj.dapp[dappI].dappname;
          for (let i = 0; i < chainObj.dapp[dappI].contract.length; i++) {
            const item = chainObj.dapp[dappI].contract[i];
            const isbalance = _balance.filter(
              (v) =>
                v.contractAddress.toLowerCase() === item.contractAddress.toLowerCase() &&
                v.dappname.toLowerCase() === item.dappname.toLowerCase() &&
                v.chain === item.chainkey,
            );

            let _item: IVault;
            if (item.vault) {
              _item = {
                ...item,
                vault: {
                  ...item.vault,
                  isbalance: isbalance && isbalance.length > 0 ? true : false,
                },
              };
            } else if (item.stake) {
              _item = {
                ...item,
                stake: {
                  ...item.stake,
                  isbalance: isbalance && isbalance.length > 0 ? true : false,
                },
              };
            }
            if (!_vaultData[chain]) {
              _vaultData[chain] = {
                chain: chain,
                status: 1,
                dapp: [
                  {
                    dappname: dappname,
                    contract: [{ ...item }],
                  },
                ],
              };
            }
            if (!_vaultData[chain].dapp[dappI]) {
              _vaultData[chain].dapp = [
                ..._vaultData[chain].dapp,
                {
                  dappname: dappname,
                  contract: [{ ...item }],
                },
              ];
            }
            _vaultData[chain].dapp[dappI].contract[i] = _item;
          }
        }
      }
      state.chosedData = _vaultData;
      state.accountBalanceInfo = accountBalanceInfo;
    }
  });
};
export default vaultExtraReducers;
