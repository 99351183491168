import { VaultMatadateType } from 'state/types';
import { Call, RPCApi } from 'server/RPCApi';
import { CHAINID } from 'config/constants/chain_config';
import { IVault } from '../types';
import { getFromaterParmas } from '../utils';
import { getMultiObj } from './fetchVaultAddressMulti';
import balanceOf_abi from 'config/abi/balanceOf.json';

export const fetchMultiUserPendingStargate = async (
  account: string,
  safeAddress: Record<string, Record<string, string>>,
  allVaultsData: VaultMatadateType,
) => {
  try {
    let obj = {};
    const result = await Promise.all(
      Object.keys(allVaultsData).map((chain) => {
        const chainkey = chain as CHAINID;
        const _chain = allVaultsData[chain];
        const _dapp = _chain.dapp;
        const { obj: _obj, vaultsData } = getFromaterParmas({ obj, dapps: _dapp, chain });
        obj = _obj;
        const otherAccount = safeAddress[account][chainkey];
        return fetchMultiUserPendingStargateItem([account, otherAccount], vaultsData, chainkey);
      }),
    );
    obj = getMultiObj(obj, allVaultsData, result);
    return obj;
  } catch (e: any) {
    console.error('fetchMultiUserPendingStargate: ', e);
    console.info('fetchMultiUserPendingStargate: ', e);
    throw new Error(e?.message);
  }
};
const fetchMultiUserPendingStargateItem = async (account: string[], vaults: IVault[], chain: CHAINID) => {
  const rpcApi = new RPCApi({
    chainkey: chain,
  });
  const vaultCallsArr = {};
  for (let i = 0; i < account.length; i++) {
    vaultCallsArr[account[i]] = vaults.map((vault: IVault) => {
      if (vault.stake) {
        return {
          address: vault.stake.lpstaking,
          name: vault.stake.earnedaddresssymbol[0].fnname,
          params: [vault.stake.pid, account[i]],
        };
      }
      return {
        address: vault.contractAddress,
        name: 'balanceOf',
        params: [account[i]],
      };
    });
  }
  const vaultCall = [...Object.values(vaultCallsArr)].flat(2) as Call[];
  const result = await rpcApi.multicall(balanceOf_abi, vaultCall);
  const obj = {};
  //
  for (let i = 0; i < account.length; i++) {
    const start = i * vaults.length;
    const end = start + vaults.length;
    obj[`${account[i]}`] = result.slice(start, end).map((v) => v[0].toString());
  }
  // {
  //     "userVaultBalances": {
  //       "arbitrum": {
  //           "AAVE": {
  //               "0x2E1C9Adc548963273d9e767413403719019bd639": [
  //                   "0",
  //                   "0"
  //               ],
  //               "0x4cE4CAc899b8B33eA75D93e780FA4dD1a74954b4": [
  //                   "0",
  //                   "0"
  //               ]
  //           }
  //       }
  //   }
  // }
  return obj;
};
