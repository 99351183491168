import { STATIC_BASE_URL } from 'config';
import { CHAINID } from 'config/constants/chain_config';
import { getTokenConfig } from 'lifiConfig/getTokenConfig';
import { ZERO_ADDRESS } from 'state/safeContract/utils/utils';
import { getTokenUrl } from './getTokenUrl';

export const haveNumber = (num: number) => {
  return num || num === 0;
};

export const getDappImage = (dapp: string) => {
  return `${STATIC_BASE_URL}/images/dappv2/${dapp.toLowerCase()}.png`;
};

export const getBridgeImage = (bridge: string) => {
  return `${STATIC_BASE_URL}/images/bridge/${bridge}.png`;
};

export const getImageUrlFromToken = (token: any, chainkeyFromSource: CHAINID | number) => {
  let tokenAddress = '';
  if (typeof token?.address === 'string') {
    tokenAddress = token.address.toLocaleLowerCase();
  } else if (!token || `${token}` === 'undefined' || !token.length) {
    tokenAddress = ZERO_ADDRESS;
  } else if (typeof token === 'string') {
    tokenAddress = token.toLocaleLowerCase();
  }
  const tokenObj = getTokenConfig(tokenAddress, chainkeyFromSource);
  return getTokenUrl(tokenObj?.logoURI) || `${STATIC_BASE_URL}/images/tokens/${chainkeyFromSource}/${tokenAddress}.svg`;
};
