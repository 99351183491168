import { CHAINID } from 'config/constants/chain_config';
import AvaultRouter_ARBITRUM from './abi/AvaultRouter_ARBITRUM.json';
import AvaultRouter_OPTIMISM from './abi/AvaultRouter_OPTIMISM.json';
import SigValidationModule from './abi/SigValidationModule.json';
import UOExecutorL2 from './abi/UOExecutorL2.json';
import IStargateRouter from './abi/IStargateRouter.json';
import fetchVaultABIAmountMulti from './abi/fetchVaultABIAmountMulti.json';
import LayerZeroUA from './abi/LayerZeroUA.json';
import lzEndpoint from './abi/lzEndpoint.json';
import MultiSendCallOnly from './abi/MultiSendCallOnly.json';
import MultiSend from './abi/MultiSend.json';

import SmartDepositor from './abi/SmartDepositor.json';
import StargateRouter from './abi/StargateRouter.json';
import StargateLPStaking from './abi/StargateLPStaking.json';
import StargateRouterETH from './abi/StargateRouterETH.json';

export const routerContractABI = {
  [CHAINID.ARBITRUM_MAINNET]: AvaultRouter_ARBITRUM,
  [CHAINID.OPTIMISTIC_MAINNET]: AvaultRouter_OPTIMISM,
};
export const SigValidationModuleABI = SigValidationModule;
export const UOExecutorL2ABI = UOExecutorL2;

export const IStargateRouterABI = IStargateRouter;
export const fetchVaultABIAmountMultiABI = fetchVaultABIAmountMulti;
export const LayerZeroUAABI = LayerZeroUA;
export const lzEndpointABI = lzEndpoint;

export const MultiSendCallOnlyABI = MultiSendCallOnly;
export const MultiSendABI = MultiSend;

export const StargateRouterABI = StargateRouter;
export const StargateLPStakingABI = StargateLPStaking;
export const SmartDepositorABI = SmartDepositor;
export const StargateRouterETHABI = StargateRouterETH;
