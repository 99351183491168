export enum CHAINID {
  ETH_MAINNET = '1',
  ETH_ROPSTEN = '3',
  Ontology = '5851',
  // todo
  ETH_RINKEBY = '4',
  // ----
  OPTIMISTIC_MAINNET = '10',
  BSC_MAINNET = '56',
  Celo_MAINNET = '42220',
  OEC_MAINNET = '66',
  MoonbaseAlpha_MAINNET = '1287',
  Clover_Mainnet = '1024',
  OPTIMISTIC_TESTNET = '69',
  ASTR_TESTNET = '81',
  SDN_TESTNET = '82',
  BSC_TESTNET = '87',

  XDAI_MAIN = '100',

  HECO_MAINNET = '128',
  POLYGON_MAINNET = '137',
  FANTOM_MAINNET = '250',
  BOBA_MAINNET = '288',
  SHIDEN_MAINNET = '336',
  THETA_MAINNET = '361',
  THETA_TESTNET = '365',
  ASTR_MAINNET = '592',

  SDN_MAINNET = '336',

  FANTOM_TESTNET = '4002',
  ARBITRUM_MAINNET = '42161',
  ARBITRUM_TESTNET = '421611',
  POLYGON_TESTNET = '80001',
  AVALANCH_MAINNET = '43114',
  AVALANCH_TESTNET = '43113',
}
