import { CHAINID } from 'config/constants/chain_config';
import { CHAINLISTID } from 'lifiConfig/getWalletConfig';
import { useMemo } from 'react';
import { IMultiToken, IMultiTokenItem } from 'state/types';
import { IVault } from 'state/vault/types';

export function useGetFromTokemList({
  account,
  multiToken,
  multiTokenConfig,
  fromchain,
  chosedVault,
  vaultIsTo = false,
}: {
  account: string;
  multiToken: IMultiToken;
  multiTokenConfig: IMultiToken;
  fromchain: CHAINID;
  chosedVault?: IVault;
  vaultIsTo?: boolean;
}) {
  return useMemo(() => {
    const _multiToken =
      account && multiToken && Object.keys(multiToken).length ? multiToken || multiTokenConfig : multiTokenConfig;
    if (!_multiToken) {
      return {};
    }
    const chainList = CHAINLISTID;
    let _fromchain = vaultIsTo ? fromchain : chosedVault?.chainkey || fromchain;
    if (!chainList.includes(_fromchain)) {
      _fromchain = chainList[0] as CHAINID;
    }
    const fromtokenList: IMultiTokenItem[] = _multiToken[_fromchain];

    if (!fromtokenList) {
      return {};
    }

    return {
      fromtokenList,
    };
  }, [account, chosedVault?.chainkey, fromchain, multiToken, multiTokenConfig, vaultIsTo]);
}
