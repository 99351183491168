import { createSlice } from '@reduxjs/toolkit';
import { CHAINID } from 'config/constants/chain_config';
import { getWalletConfig } from 'lifiConfig/getWalletConfig';
import { IChainItemType } from 'lifiConfig/IChainType';
import { chain_key_localstorage_id, getLocalStorage } from 'utils/localStorage';
import GlobalExtraReducers from './actions';
import { GlobalState } from './types';

const initialState: GlobalState = {
  processList: null,
  isMultiHistoryLoading: true,
  multiHistory: [],
  chain: null,
};
export const globalSlice = createSlice({
  name: 'global',
  initialState: initialState,
  reducers: {
    getChainIdKeyState: (state) => {
      state.chain = getChainIdKey();
      // console.log('state.chainkey: ', state.chain);
    },
    setChainKeyState: (state, action) => {
      state.chain = action.payload;
    },
  },
  extraReducers: GlobalExtraReducers,
});
export const { getChainIdKeyState, setChainKeyState } = globalSlice.actions;
export const getChainIdKey = (): IChainItemType => {
  const chainId = window.ethereum?.networkVersion || '';
  // console.log(chainId);
  // console.log({ chainId });
  if (chainId) {
    return getWalletConfig(chainId as CHAINID);
  }
  const local = getLocalStorage(chain_key_localstorage_id);
  // console.log(local);
  if (local) {
    return local as IChainItemType;
  }
  return getWalletConfig(CHAINID.ARBITRUM_MAINNET);
};
export default globalSlice.reducer;
