import BigNumber from 'bignumber.js';
import { fetchVaultABIAmountMulti } from './multi/fetchVaultAddressMulti';
import { BIG_ZERO } from 'utils/bigNumber';
import { fetchApy, INetValueKeyItemItem } from './multi/fetchApy';
import { VaultMatadateType } from 'state/types';
import { IEventType } from './types';
import { isNaNString } from './utils';
import { CHAINID } from 'config/constants/chain_config';

const fetchVaultsV2 = async (vaultsData: VaultMatadateType): Promise<[VaultMatadateType, string]> => {
  // base
  // ----------------AVVATADDRESS Farm----------
  // const {
  //   masterChef,
  //   name,
  //   symbol,
  //   pid,
  //   wantaddress,
  //   token0address,
  //   token1address,
  //   earnedaddress,
  //   avaaddress,
  //   vaultDecimals,
  // } = await fetchVaultABIBaseMulti(vaultsData);
  //   masterChef,
  //   name,
  //   symbol,
  //   pid,
  //   wantaddress,
  //   token0address,
  //   token1address,
  //   earnedaddress,
  //   avaaddress,
  //   vaultDecimals,
  // });
  // ----------------AVVATADDRESS SingleToken----------
  // const { name, symbol, wantaddress, earnedaddress, avaaddress, vaultDecimals } = await fetchVaultABIBaseSingleToken(
  //   vaultsData,
  // );
  //   name,
  //   symbol,
  //   wantaddress,
  //   earnedaddress,
  //   avaaddress,
  //   vaultDecimals,
  // });

  // // pid: (22) [21, 20, 19, 18, 17, 16, 15, 14, 13, 12, 11, 10, 9, 8, 7, 6, 5, 4, 3, 2, 1, 0]
  const obj = {} as VaultMatadateType;
  const _fetchVaultABIAmount = await fetchVaultABIAmountMulti(vaultsData);
  // ----------------AVVATADDRESS  end----------
  // -------------MASTRETADDRESS--------
  // const { poolWeight, multiplier } = await fetchMasterChefABI(currentBlock, vaultsData);
  //-----------------Farm----------
  // const { lpAddressDecimals, tokenDecimals, quoteTokenDecimals, lpSymbol } = await fetchFarmDataABIBase(vaultsData);
  // const {
  //   // tokenAmountMc,
  //   // tokenAmountTotal,
  //   // quoteTokenAmountMc,
  //   // quoteTokenAmountTotal,
  //   // lpTotalSupply,
  //   // lpTotalInQuoteToken,
  //   liquidity,
  //   // tokenPriceVsQuote,
  //   // lpTokenPrice,
  // } = await fetchFarmDataABICalc(vaultsData, priceVsBusdMapMulti);
  // calc amount cant stable
  try {
    let _total = BIG_ZERO;
    const apyArr = await fetchApy();
    for (const chain of Object.keys(vaultsData)) {
      const chainObj = vaultsData[chain];
      if (chainObj.dapp && chainObj.dapp.length) {
        for (let dappI = 0; dappI < chainObj.dapp.length; dappI++) {
          const dappname = chainObj.dapp[dappI].dappname;
          const { wantLockedTotal, vaultTotalSupply, scale = [], amountLPtoLD } = _fetchVaultABIAmount[chain][dappname];
          for (let i = 0; i < chainObj.dapp[dappI].contract.length; i++) {
            const __wantLockedTotal = wantLockedTotal[i];
            const __vaultTotalSupply = vaultTotalSupply[i];
            const __scale = scale[i];
            const item = chainObj.dapp[dappI].contract[i];
            const _apyItem: INetValueKeyItemItem = apyArr[chain][item.contractAddress.toLowerCase()];

            const _liquidity = _apyItem.tvlUsd;
            // const _wantaddress = item.vault ? item.vault.wantaddress : item.stake.wantaddress;
            // const _decimals = item.vault ? item.vault.decimals : item.stake.wantaddressdecimals;
            // const tokenC = await fetchToken(item.chainkey, _wantaddress);
            // let _liquidity = 0;
            // _liquidity = new BigNumber(__wantLockedTotal)
            //   .div(BIG_TEN.pow(new BigNumber(_decimals)))
            //   .times(Number(tokenC.priceUSD))
            //   .toNumber();

            _total = _total.plus(_liquidity);
            const _scale = item.eventtype === IEventType.normal ? '1' : __scale || '1';
            const _lpToCLpRate =
              __wantLockedTotal && __vaultTotalSupply && __wantLockedTotal > 0 && __vaultTotalSupply > 0
                ? new BigNumber(__wantLockedTotal).div(__vaultTotalSupply).toFixed(18)
                : '1';
            let _item;
            if (item.vault) {
              _item = {
                ...item,
                vault: {
                  ...item.vault,
                  total_apy: _apyItem.apy ? new BigNumber(_apyItem.apy).toFixed(2) : '-',
                  base_apy: _apyItem.apyBase ? new BigNumber(_apyItem.apyBase).toFixed(2) : '-',
                  reward_apy: _apyItem.apyReward ? new BigNumber(_apyItem.apyReward).toFixed(2) : '-',
                  wantLockedTotal: __wantLockedTotal,
                  totalSupply: __vaultTotalSupply,
                  scale: _scale,
                  tvl: _liquidity.toLocaleString('en-US', {
                    maximumFractionDigits: 2,
                  }),
                  lpToCLpRate: _lpToCLpRate,
                },
              };
            } else if (item.stake) {
              const __amountLPtoLD = amountLPtoLD[i];
              _item = {
                ...item,
                stake: {
                  ...item.stake,
                  total_apy: _apyItem.apy ? new BigNumber(_apyItem.apy).toFixed(2) : '-',
                  base_apy: _apyItem.apyBase ? new BigNumber(_apyItem.apyBase).toFixed(2) : '-',
                  reward_apy: _apyItem.apyReward ? new BigNumber(_apyItem.apyReward).toFixed(2) : '-',
                  wantLockedTotal: __wantLockedTotal,
                  totalSupply: __vaultTotalSupply,
                  scale: _scale,
                  tvl: _liquidity.toLocaleString('en-US', {
                    maximumFractionDigits: 2,
                  }),
                  lpToCLpRate: _lpToCLpRate,
                  // amountLPtoLD
                  amountLPtoLD: __amountLPtoLD,
                },
              };
            }
            if (!obj[chain]) {
              obj[chain] = {
                chain: chain as CHAINID,
                status: 1,
                dapp: [
                  {
                    dappname: dappname,
                    contract: [{ ...item }],
                  },
                ],
              };
            }
            if (!obj[chain].dapp[dappI]) {
              obj[chain].dapp = [
                ...obj[chain].dapp,
                {
                  dappname: dappname,
                  contract: [{ ...item }],
                },
              ];
            }
            obj[chain].dapp[dappI].contract[i] = _item;
          }
        }
      } else {
        if (!obj[chain]) {
          obj[chain] = chainObj;
        }
      }

      // const priceAddress =
      //   item.dappname === IDAppName.arthswap ? tokens[chainkey].arsw.address.toLowerCase() : '';
      // const { kacRewardsApr, kacRewardApy } = getFarmApr(
      //   new BigNumber(perBlock[i]),
      //   new BigNumber(poolWeight[i]),
      //   new BigNumber(priceVsBusdMap[priceAddress] ?? '1'),
      //   new BigNumber(liquidity[i]),
      //   item.lpDetail.address,
      // );
      // const feeApr: number = vault_fee_apr[`${item.vault.symbol}`];
      // const rewardApy = aprToApy(feeApr);
    }
    const total = isNaNString(_total.toString());
    return [obj, total];
  } catch (e: any) {
    console.error('fetchVaultsV2:', e);
    console.info('fetchVaultsV2:', e);
    throw new Error(e?.message);
  }
};

export default fetchVaultsV2;
