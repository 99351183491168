import { JsonFragment } from '@ethersproject/abi';
import BigNumber from 'bignumber.js';
import { CHAINID } from 'config/constants/chain_config';
import { ethers } from 'ethers';
import { Fragment } from 'ethers/lib/utils';
import { Operation } from 'views/Trade/types';
import { SafeTransaction } from '../types/types';

// "SafeTx(address to,uint256 value,bytes data,uint8 operation,uint256 safeTxGas,uint256 baseGas,uint256 gasPrice,address gasToken,address refundReceiver,uint256 nonce)"
export const EIP712_SAFE_TX_TYPE = [
  { type: 'address', name: 'to' },
  { type: 'uint256', name: 'value' },
  { type: 'bytes', name: 'data' },
  { type: 'uint8', name: 'operation' },
  { type: 'uint256', name: 'safeTxGas' },
  { type: 'uint256', name: 'baseGas' },
  { type: 'uint256', name: 'gasPrice' },
  { type: 'address', name: 'gasToken' },
  { type: 'address', name: 'refundReceiver' },
  { type: 'uint256', name: 'nonce' },
];
const SAFE_CREATIONCODE =
  '0x608060405234801561001057600080fd5b506040516101e63803806101e68339818101604052602081101561003357600080fd5b8101908080519060200190929190505050600073ffffffffffffffffffffffffffffffffffffffff168173ffffffffffffffffffffffffffffffffffffffff1614156100ca576040517f08c379a00000000000000000000000000000000000000000000000000000000081526004018080602001828103825260228152602001806101c46022913960400191505060405180910390fd5b806000806101000a81548173ffffffffffffffffffffffffffffffffffffffff021916908373ffffffffffffffffffffffffffffffffffffffff1602179055505060ab806101196000396000f3fe608060405273ffffffffffffffffffffffffffffffffffffffff600054167fa619486e0000000000000000000000000000000000000000000000000000000060003514156050578060005260206000f35b3660008037600080366000845af43d6000803e60008114156070573d6000fd5b3d6000f3fea2646970667358221220d1429297349653a4918076d650332de1a1068c5f3e07c5c82360c277770b955264736f6c63430007060033496e76616c69642073696e676c65746f6e20616464726573732070726f7669646564';
export const ZERO_ADDRESS = '0x0000000000000000000000000000000000000000';
// const SAFE_CALLBACK = {
//   [CHAINID.OPTIMISTIC_MAINNET]: '0x017062a1dE2FE6b99BE3d9d37841FeD19F573804',
//   [CHAINID.ARBITRUM_MAINNET]: '0xf48f2B2d2a534e402487b3ee7C18c33Aec0Fe5e4',
// };
// add chain
const SAFE_CALLBACK = {
  [CHAINID.ETH_MAINNET]: '0xf48f2B2d2a534e402487b3ee7C18c33Aec0Fe5e4',
  '3': '0xf48f2B2d2a534e402487b3ee7C18c33Aec0Fe5e4',
  '4': '0xf48f2B2d2a534e402487b3ee7C18c33Aec0Fe5e4',
  [CHAINID.OPTIMISTIC_MAINNET]: '0x017062a1dE2FE6b99BE3d9d37841FeD19F573804',
  '11': '0xf48f2B2d2a534e402487b3ee7C18c33Aec0Fe5e4',
  '12': '0xf48f2B2d2a534e402487b3ee7C18c33Aec0Fe5e4',
  '28': '0x017062a1dE2FE6b99BE3d9d37841FeD19F573804',
  '42': '0xf48f2B2d2a534e402487b3ee7C18c33Aec0Fe5e4',
  '5': '0xf48f2B2d2a534e402487b3ee7C18c33Aec0Fe5e4',
  [CHAINID.BSC_MAINNET]: '0xf48f2B2d2a534e402487b3ee7C18c33Aec0Fe5e4',
  '69': '0x017062a1dE2FE6b99BE3d9d37841FeD19F573804',
  '82': '0x017062a1dE2FE6b99BE3d9d37841FeD19F573804',
  '83': '0x017062a1dE2FE6b99BE3d9d37841FeD19F573804',
  '100': '0xf48f2B2d2a534e402487b3ee7C18c33Aec0Fe5e4',
  '106': '0x017062a1dE2FE6b99BE3d9d37841FeD19F573804',
  '111': '0x017062a1dE2FE6b99BE3d9d37841FeD19F573804',
  '122': '0xf48f2B2d2a534e402487b3ee7C18c33Aec0Fe5e4',
  '123': '0xf48f2B2d2a534e402487b3ee7C18c33Aec0Fe5e4',
  [CHAINID.POLYGON_MAINNET]: '0xf48f2B2d2a534e402487b3ee7C18c33Aec0Fe5e4',
  '246': '0xf48f2B2d2a534e402487b3ee7C18c33Aec0Fe5e4',
  '288': '0x017062a1dE2FE6b99BE3d9d37841FeD19F573804',
  '300': '0xf48f2B2d2a534e402487b3ee7C18c33Aec0Fe5e4',
  '588': '0x017062a1dE2FE6b99BE3d9d37841FeD19F573804',
  '1008': '0xf48f2B2d2a534e402487b3ee7C18c33Aec0Fe5e4',
  '1088': '0x017062a1dE2FE6b99BE3d9d37841FeD19F573804',
  '1284': '0xf48f2B2d2a534e402487b3ee7C18c33Aec0Fe5e4',
  '1285': '0xf48f2B2d2a534e402487b3ee7C18c33Aec0Fe5e4',
  '1287': '0xf48f2B2d2a534e402487b3ee7C18c33Aec0Fe5e4',
  '1984': '0xf48f2B2d2a534e402487b3ee7C18c33Aec0Fe5e4',
  '4002': '0xf48f2B2d2a534e402487b3ee7C18c33Aec0Fe5e4',
  '7341': '0xf48f2B2d2a534e402487b3ee7C18c33Aec0Fe5e4',
  '9000': '0xf48f2B2d2a534e402487b3ee7C18c33Aec0Fe5e4',
  '9001': '0xf48f2B2d2a534e402487b3ee7C18c33Aec0Fe5e4',
  '10000': '0x017062a1dE2FE6b99BE3d9d37841FeD19F573804',
  '10001': '0x017062a1dE2FE6b99BE3d9d37841FeD19F573804',
  '11437': '0xf48f2B2d2a534e402487b3ee7C18c33Aec0Fe5e4',
  '12357': '0xf48f2B2d2a534e402487b3ee7C18c33Aec0Fe5e4',
  [CHAINID.ARBITRUM_MAINNET]: '0xf48f2B2d2a534e402487b3ee7C18c33Aec0Fe5e4',
  '42220': '0x017062a1dE2FE6b99BE3d9d37841FeD19F573804',
  '43114': '0x017062a1dE2FE6b99BE3d9d37841FeD19F573804',
  '47805': '0xf48f2B2d2a534e402487b3ee7C18c33Aec0Fe5e4',
  '73799': '0xf48f2B2d2a534e402487b3ee7C18c33Aec0Fe5e4',
  '80001': '0xf48f2B2d2a534e402487b3ee7C18c33Aec0Fe5e4',
  '333999': '0xf48f2B2d2a534e402487b3ee7C18c33Aec0Fe5e4',
  '421611': '0xf48f2B2d2a534e402487b3ee7C18c33Aec0Fe5e4',
  '1313161554': '0xf48f2B2d2a534e402487b3ee7C18c33Aec0Fe5e4',
  '1313161555': '0xf48f2B2d2a534e402487b3ee7C18c33Aec0Fe5e4',
  '1666600000': '0x017062a1dE2FE6b99BE3d9d37841FeD19F573804',
  '1666700000': '0x017062a1dE2FE6b99BE3d9d37841FeD19F573804',
  '11297108099': '0xf48f2B2d2a534e402487b3ee7C18c33Aec0Fe5e4',
  '11297108109': '0xf48f2B2d2a534e402487b3ee7C18c33Aec0Fe5e4',
};
const SALT_NONCE = 0;
// const SAFE_SINGLETON_ARBITRUM = '0x3E5c63644E683549055b9Be8653de26E0B4CD36E';
// from: SAFE_FACTORY
export const calculateUserAddress = ({
  MODULE_ENABLER,
  safeSingleton,
  from,
  account,
  chain,
}: {
  MODULE_ENABLER: string;
  safeSingleton: string;
  from: string;
  account: string;
  chain: CHAINID;
}) => {
  const _iface_enalbleModule = new ethers.utils.Interface(['function enableModule();']);
  const enableModuleEncode = _iface_enalbleModule.encodeFunctionData('enableModule', []);

  const packedCreationCode = ethers.utils.solidityPack(
    ['bytes', 'bytes'],
    [SAFE_CREATIONCODE, ethers.utils.defaultAbiCoder.encode(['address'], [safeSingleton])],
  );
  // if (chain === CHAINID.ARBITRUM_MAINNET) {
  // console.log('enableModuleEncode111222:', packedCreationCode);
  // }
  const initCodeHash = ethers.utils.keccak256(packedCreationCode);

  const iface = new ethers.utils.Interface([
    'function setup(address[], uint, address, bytes, address, address, uint, address)',
  ]);
  const functionFragment = 'setup(address[], uint, address, bytes, address, address, uint, address)';
  const initialzierCalldata = iface.encodeFunctionData(functionFragment, [
    [account],
    1,
    MODULE_ENABLER,
    enableModuleEncode,
    SAFE_CALLBACK[chain],
    ZERO_ADDRESS,
    0,
    ZERO_ADDRESS,
  ]);
  // console.log(`initialzierCalldata: ${initialzierCalldata}`, SAFE_CALLBACK[chain]);
  const salt = ethers.utils.keccak256(
    ethers.utils.solidityPack(['bytes', 'uint'], [ethers.utils.keccak256(initialzierCalldata), SALT_NONCE]),
  );
  const safeAddress = ethers.utils.getCreate2Address(from, salt, initCodeHash);
  return safeAddress.toLowerCase();
};

export const executeContractCallWithSigners = () => {};

export const getTx = ({
  abi,
  method,
  params,
  toAddress,
  delegateCall,
  nonce,
  overrides,
}: {
  abi: ReadonlyArray<Fragment | JsonFragment | string>;
  method: string;
  params: any[];
  toAddress: string;
  delegateCall: Operation;
  nonce: number;
  overrides?: Partial<SafeTransaction>;
}) => {
  const itf = new ethers.utils.Interface(abi);
  const data = itf.encodeFunctionData(method, params);
  const tx = buildSafeTransaction(
    Object.assign(
      {
        to: toAddress,
        data,
        operation: delegateCall ? 1 : 0,
        nonce,
      },
      overrides,
    ),
  );
  return tx;
};

const buildSafeTransaction = (template: {
  to: string;
  value?: BigNumber | number | string;
  data?: string;
  operation?: number;
  safeTxGas?: number | string;
  baseGas?: number | string;
  gasPrice?: number | string;
  gasToken?: string;
  refundReceiver?: string;
  nonce: number;
}): SafeTransaction => {
  return {
    to: template.to,
    value: template.value || 0,
    data: template.data || '0x',
    operation: template.operation || 0,
    safeTxGas: template.safeTxGas || 0,
    baseGas: template.baseGas || 0,
    gasPrice: template.gasPrice || 0,
    gasToken: template?.gasToken || ZERO_ADDRESS,
    refundReceiver: template.refundReceiver || ZERO_ADDRESS,
    nonce: template.nonce,
  };
};

interface SafeSignature {
  signer: string;
  data: string;
}

export const buildSignatureBytes = (signatures: SafeSignature[]): string => {
  signatures.sort((left, right) => left.signer.toLowerCase().localeCompare(right.signer.toLowerCase()));
  let signatureBytes = '0x';
  for (const sig of signatures) {
    signatureBytes += sig.data.slice(2);
  }
  return signatureBytes;
};
