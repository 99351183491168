export const chain_key_localstorage_id = 'chain_key_localstorage_id';
export const setLocalStorage = (key: string, value: any) => {
  localStorage.setItem(key, JSON.stringify(value));
};

export const getLocalStorage = (key: string) => {
  const val = localStorage.getItem(key);
  try {
    return JSON.parse(val);
  } catch {
    return val;
  }
};
