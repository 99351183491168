import { SvgProps } from 'components/svg/types';
import React from 'react';

const ListIcon: React.FC<SvgProps> = (props) => {
  return (
    <svg viewBox="0 0 30 30" {...props}>
      <g transform="translate(3.75 1.875)" fill="none" fill-rule="evenodd">
        <rect stroke="#6A698E" stroke-width="1.6" x=".8" y=".8" width="20.9" height="24.65" rx="8" />
        <rect fill="#6A698E" fill-rule="nonzero" x="5.625" y="13.125" width="11.25" height="1.5" rx=".75" />
        <rect fill="#6A698E" fill-rule="nonzero" x="5.625" y="17.8125" width="11.25" height="1.5" rx=".75" />
        <path
          fill="#6A698E"
          d="M7.54187775 5.625A1.875 1.875 0 1 0 7.54187775 9.375A1.875 1.875 0 1 0 7.54187775 5.625Z"
        />
        <path
          fill="#6A698E"
          d="M12.6981278 6.5625A1.40625 1.40625 0 1 0 12.6981278 9.375A1.40625 1.40625 0 1 0 12.6981278 6.5625Z"
        />
      </g>
    </svg>
  );
};

export default ListIcon;
