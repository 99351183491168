import { CHAINID } from 'config/constants/chain_config';
import { IMultiTokenItem } from 'state/types';
import Tokens from './tokens.json';
export const getTokenConfig = (address: string, chainId: CHAINID | number): IMultiTokenItem => {
  const TokensObj = Tokens.tokens[chainId].filter((v) => `${v.address.toLowerCase()}` === address.toLowerCase());
  if (TokensObj) {
    return TokensObj[0] as IMultiTokenItem;
  }
};

export interface tokenPriceLifiTypeMap {
  [_idAddress: string]: tokenPriceLifiType;
}

export interface tokenPriceLifiType {
  address: string;
  symbol: string;
  decimals: number;
  chainId: number;
  name: string;
  coinKey: string;
  priceUSD: string;
  logoURI: string;
}

export const tokenPricesSearchParamKey = 'chainIdAddress';

export function createTokenPricesKey(chainId: string, address: string) {
  return chainId + '_' + address;
}
