import React from 'react';
import {
  TokenPairImage as UIKitTokenPairImage,
  TokenPairImageProps as UIKitTokenPairImageProps,
  TokenImage as UIKitTokenImage,
  ImageProps,
} from '@my/ui';
import { Token } from 'config/constants/types';
import { getImageUrlFromToken } from 'utils';
import { CHAINID } from 'config/constants/chain_config';

interface TokenPairImageProps extends Omit<UIKitTokenPairImageProps, 'primarySrc' | 'secondarySrc'> {
  primaryToken: string;
  secondaryToken: string;
  chainkeyFromSource: CHAINID;
}

export const TokenPairImage: React.FC<TokenPairImageProps> = ({
  primaryToken,
  secondaryToken,
  chainkeyFromSource,
  ...props
}) => {
  return (
    <UIKitTokenPairImage
      primarySrc={getImageUrlFromToken(primaryToken, chainkeyFromSource)}
      secondarySrc={getImageUrlFromToken(secondaryToken, chainkeyFromSource)}
      {...props}
    />
  );
};

interface TokenImageProps extends ImageProps {
  token: Token;
  chainkeyFromSource: CHAINID;
}

export const TokenImage: React.FC<TokenImageProps> = ({ token, chainkeyFromSource, ...props }) => {
  return <UIKitTokenImage src={getImageUrlFromToken(token, chainkeyFromSource)} {...props} />;
};
