import { BASE_URL } from 'config';

// export const fetchTokenPrice = async () => {
//   try {
//     const apiUrl = `${BASE_URL}/api/v0/token/price`;
//     const r = await fetch(apiUrl);
//     const body = await r.json();
//     return body.data;
//   } catch (e: any) {
//     return {};
//   }
// };

export const fetchMultiTokenPrice = async () => {
  try {
    const apiUrl = `${BASE_URL}/api/v0/multi/token/price`;
    const r = await fetch(apiUrl);
    const body = await r.json();
    return body.data;
  } catch (e: any) {
    return {};
  }
};
