import { useState } from 'react';
import { copyText } from 'utils/copyText';
import styled from 'styled-components';
import { SvgProps } from '@my/ui';
import CopyIcon from 'components/svg/CopyIcon';

const Tooltip = styled.p<{
  isTooltipDisplayed: boolean;
  tooltipTop: number;
  tooltipRight: number;
  tooltipFontSize?: number;
}>`
  display: ${({ isTooltipDisplayed }) => (isTooltipDisplayed ? 'inline' : 'none')};
  position: absolute;
  padding: 6px 12px;
  top: ${({ tooltipTop }) => `${tooltipTop}px`};
  right: ${({ tooltipRight }) => (tooltipRight ? `${tooltipRight}px` : 0)};
  text-align: center;
  font-size: ${({ tooltipFontSize }) => `${tooltipFontSize}px` ?? '100%'};
  background-color: ${({ theme }) => theme.colors.background02};
  color: ${({ theme }) => theme.colors.green};
  border-radius: 8px;
  opacity: 0.9;
  width: max-content;
  border: 1px solid ${({ theme }) => theme.colors.green};
`;

interface CopyButtonProps extends SvgProps {
  text: string;
  tooltipMessage: string;
  tooltipTop: number;
  tooltipRight?: number;
  tooltipFontSize?: number;
  buttonColor?: string;
  ParantProps?: any;
}

export const CopyButton: React.FC<CopyButtonProps> = ({
  text,
  tooltipMessage,
  width,
  tooltipTop,
  tooltipRight,
  tooltipFontSize,
  buttonColor = 'primary',
  children,
  ParantProps = 'div',
  ...props
}) => {
  const [isTooltipDisplayed, setIsTooltipDisplayed] = useState(false);
  const displayTooltip = () => {
    setIsTooltipDisplayed(true);
    setTimeout(() => {
      setIsTooltipDisplayed(false);
    }, 1000);
  };
  return (
    <ParantProps onClick={() => copyText(text, displayTooltip)}>
      <CopyIcon style={{ cursor: 'pointer' }} color={buttonColor} width={width} {...props} />
      {children ? children : null}
      <Tooltip
        isTooltipDisplayed={isTooltipDisplayed}
        tooltipTop={tooltipTop}
        tooltipRight={tooltipRight}
        tooltipFontSize={tooltipFontSize}
      >
        {tooltipMessage}
      </Tooltip>
    </ParantProps>
  );
};
