import { Button, useTooltip } from '@my/ui';
import BigNumber from 'bignumber.js';
import IconLink from 'components/svg/IconLink';
import { CHAINID } from 'config/constants/chain_config';
import { routePath } from 'config/constants/meta';
import { subStringAccount } from 'hooks/useAccount';
import { useJumpPath } from 'hooks/useJumpPath';
import { IChainItemType } from 'lifiConfig/IChainType';
import { orderBy } from 'lodash';
import { memo, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useGlobalState } from 'state/global/hooks';
import { IMultiToken, IMultiTokenItem, SafeAddress } from 'state/types';
import { useThemeContext } from 'state/user/hook';
import { IVaultType } from 'state/vault/types';
import { getLink } from 'utils/getLink';
import { useGetChainId } from 'views/Trade/hooks/get/getChainId';
import { useGetFromTokemList } from 'views/Trade/hooks/useGetFromTokemList';
import { VaultComp } from 'views/VaultMulti/components/MultiVaultTable/tableComponents/VaultComp';
import VaultWithdrawBtnIcon from '../imgs/VaultWithdrawBtn';
import { AssetListStyled, AssetStyled, ContractAccountInfoStyled } from './AssetStyled';

const Asset = ({ safeAddress, chainList, account, multiToken, multiTokenConfig, collapsed }) => {
  // console.log({ safeAddress, account, multiToken, multiTokenConfig, collapsed });
  const theme = useThemeContext();
  const setMoreTooltipVisible = useRef<React.Dispatch<React.SetStateAction<boolean>>>();
  const {
    targetRef: AssetTargetRef,
    tooltip: AssetTooltip,
    tooltipVisible: AssetTooltipVisible,
    setTooltipVisible,
  } = useTooltip(
    <AssetList
      chainList={chainList}
      safeAddress={safeAddress}
      account={account}
      multiToken={multiToken}
      multiTokenConfig={multiTokenConfig}
      setTooltipVisible={setMoreTooltipVisible.current}
      collapsed={collapsed}
    />,
    {
      trigger: 'click',
      tootipStyle: {
        // position: 'fixed',
        backgroundColor: 'transparent',
        background: theme.isDark ? theme.colors.background02 : theme.colors.background,
        border: `1px solid ${theme.colors.borderColor}`,
        boxShadow: '2px 12px 16px 0 rgba(0, 0, 0, 0.3)',
        padding: '20px',
        minWidth: '360px',
        marginTop: '7px',
      },
      // placement: 'bottom-end',
      hideArrow: false,
      arrowBackground: theme.isDark ? theme.colors.background02 : theme.colors.background,
    },
  );
  useEffect(() => {
    setMoreTooltipVisible.current = setTooltipVisible;
  }, [setTooltipVisible]);
  if (!account) {
    return null;
  }
  return (
    <>
      {AssetTooltipVisible && AssetTooltip}
      {safeAddress ? <AssetStyled ref={AssetTargetRef}>My Assets</AssetStyled> : null}
    </>
  );
};
export const AssetList = ({
  chainList,
  setTooltipVisible,
  safeAddress,
  account,
  multiToken,
  multiTokenConfig,
  collapsed,
}: {
  chainList: IChainItemType[];
  setTooltipVisible: any;
  safeAddress: SafeAddress;
  account: string;
  multiToken: IMultiToken;
  multiTokenConfig: IMultiToken;
  collapsed: boolean;
}) => {
  const { chain } = useGlobalState();
  const [chosedChain, setChosedChain] = useState<IChainItemType>(chain);
  const chosedChainId = useMemo(() => {
    return `${chosedChain.id}` as CHAINID;
  }, [chosedChain]);
  const safeContractAddress = useMemo(() => {
    if (safeAddress && account && chosedChainId && safeAddress[account] && safeAddress[account][chosedChainId]) {
      return subStringAccount(safeAddress[account][chosedChainId]);
    }
    return '';
  }, [safeAddress, account, chosedChainId]);
  const { fromtokenList } = useGetFromTokemList({
    multiToken,
    fromchain: chosedChainId,
    multiTokenConfig,
    account,
  });
  const fromtokenListLocal = useMemo(() => {
    if (fromtokenList && safeAddress && safeAddress[account] && safeAddress[account][chosedChainId]) {
      return orderBy(
        fromtokenList,
        (tokenItem: IMultiTokenItem) => {
          if (safeAddress && safeAddress[account]) {
            const childBalance =
              tokenItem.balances[safeAddress[account][chosedChainId].toLocaleLowerCase()]?.number || '0';
            const num = new BigNumber(childBalance).toFixed(4, BigNumber.ROUND_DOWN);
            return num;
          }
        },
        'desc',
      );
    }
    return [];
  }, [fromtokenList, account, chosedChainId, safeAddress]);
  const onPressChain = useCallback((v: IChainItemType) => {
    setChosedChain(v);
  }, []);
  if (!chainList || !safeAddress || !safeAddress[account]) {
    return null;
  }
  return (
    <AssetListStyled>
      <ul className="chain_list">
        {chainList.map((v, index) => (
          <li key={index} onClick={() => onPressChain(v)} className={`${chosedChain.id}` === `${v.id}` ? 'on' : ''}>
            <div className="li_inner">
              <img src={v.logoURI} alt={v.name} />
              <p>{v.name}</p>
            </div>
          </li>
        ))}
      </ul>
      {safeAddress && chainList.map((v) => `${v.id}`).includes(`${chosedChain.id}`) ? (
        <ContractAccountInfo
          account={account}
          chosedChain={chosedChain}
          safeContractAddress={safeContractAddress}
          safeAddress={safeAddress}
        />
      ) : null}
      {safeAddress &&
      chainList.map((v) => `${v.id}`).includes(`${chosedChain.id}`) &&
      fromtokenList &&
      fromtokenList.length > 0 ? (
        <ul className="token_list">
          {fromtokenListLocal.map((v: IMultiTokenItem, index: number) => (
            <TokenListItemCompant
              account={account}
              key={index}
              v={v}
              chosedChain={chosedChain}
              chosedChainId={chosedChainId}
              setTooltipVisible={setTooltipVisible}
              safeAddress={safeAddress}
              collapsed={collapsed}
            />
          ))}
        </ul>
      ) : null}
    </AssetListStyled>
  );
};
export const ContractAccountInfo = ({
  chosedChain,
  safeContractAddress,
  className,
  safeAddress,
  account,
}: {
  chosedChain: IChainItemType;
  safeContractAddress: string;
  className?: string;
  account: string;
  safeAddress: any;
}) => {
  const chosedChainId = useGetChainId(chosedChain.id);
  if (!safeAddress || !safeAddress[account] || !safeAddress[account][chosedChainId]) {
    return null;
  }
  const _safeAddress = safeAddress[account][chosedChainId];
  return (
    <ContractAccountInfoStyled className={className}>
      <img src={chosedChain.logoURI} alt={chosedChain.name} />
      <div className="fl">
        <h5>{chosedChain.name} Contract Wallet</h5>
        {safeContractAddress ? (
          <a href={getLink(_safeAddress, 'address', chosedChainId)} target="_blank" rel="noreferrer">
            <p>{subStringAccount(safeContractAddress)}</p>
            <IconLink />
          </a>
        ) : null}
      </div>
    </ContractAccountInfoStyled>
  );
};
const TokenListItemCompant = ({
  v,
  chosedChain,
  setTooltipVisible,
  safeAddress,
  collapsed,
  account,
  chosedChainId,
}: {
  v: IMultiTokenItem;
  chosedChain: IChainItemType;
  setTooltipVisible: any;
  safeAddress: Record<string, Record<string, string>>;
  collapsed: boolean;
  account: string;
  chosedChainId: CHAINID;
}) => {
  const { jump } = useJumpPath(
    `${routePath.trade_withdraw.pathPre}/${safeAddress[account][chosedChainId]}/${chosedChain.id}/${(
      v?.address || '-'
    ).toLowerCase()}`,
    () => {
      setTooltipVisible(false);
    },
  );

  return (
    <li>
      <div className="fl">
        <VaultComp
          vault={{
            wantaddress: v.address,
            wantaddresssymbol: v.symbol,
            vaulttype: [IVaultType.single, IVaultType.compound],
            chain: chosedChainId,
          }}
          width={20}
          height={20}
        />
        <h4>{v.balances[safeAddress[account][chosedChainId]]?.localNumber}</h4>
      </div>
      {collapsed ? (
        v.balances[safeAddress[account][chosedChainId]]?.localNumber !== '0' ? (
          <div className="icon" onClick={jump}>
            <VaultWithdrawBtnIcon />
          </div>
        ) : (
          <div className="icon grey">
            <VaultWithdrawBtnIcon />
          </div>
        )
      ) : v.balances[safeAddress[account][chosedChainId]]?.localNumber !== '0' ? (
        <Button variant="secondary" scale="sm" onClick={jump}>
          Withdraw
        </Button>
      ) : (
        <Button variant="text" disabled>
          Withdraw
        </Button>
      )}
    </li>
  );
};

export default memo(Asset);
