import { IMultiTokenItem } from 'state/types';
import balanceOf_abi from 'config/abi/balanceOf.json';
import BigNumber from 'bignumber.js';
import { CHAINID } from 'config/constants/chain_config';
import { RPCApi } from 'server/RPCApi';
import { ZERO_ADDRESS } from 'state/safeContract/utils/utils';
import { getWalletConfig } from 'lifiConfig/getWalletConfig';

export const fetchVaultMultiTokenBalance = async (
  tokenList: IMultiTokenItem[],
  account: string[],
  chain: CHAINID,
): Promise<any> => {
  // console.log(4, chain);
  const rpcApi = new RPCApi({
    chainkey: chain,
  });
  const vaultCallsArr = {
    balanceOfParent: [],
    balanceOfChild: [],
  };

  // console.log({ chain });
  // const _tokenList = tokenList.filter((v) => !v.address);
  vaultCallsArr.balanceOfParent = tokenList.map((v) => {
    if (!v.address || v.address === ZERO_ADDRESS) {
      const { multicallAddress } = getWalletConfig(chain);
      return {
        address: multicallAddress,
        name: 'getEthBalance',
        params: [account[0]],
      };
    }
    return {
      address: v.address,
      name: 'balanceOf',
      params: [account[0]],
    };
  });
  vaultCallsArr.balanceOfChild = tokenList.map((v) => {
    if (!v.address || v.address === ZERO_ADDRESS) {
      const { multicallAddress } = getWalletConfig(chain);
      return {
        address: multicallAddress,
        name: 'getEthBalance',
        params: [account[1]],
      };
    }
    return {
      address: v.address,
      name: 'balanceOf',
      params: [account[1]],
    };
  });
  // if (chain === CHAINID.ARBITRUM_MAINNET) {
  //   console.log({ vaultCallsArr });
  // } else {
  //   return;
  // }
  const vaultCall = [...Object.values(vaultCallsArr)].flat(2);
  const result = await rpcApi.multicall(balanceOf_abi, vaultCall);

  const obj = {};
  const keyArr = Object.keys(vaultCallsArr);
  for (let i = 0; i < keyArr.length; i++) {
    const start = i * tokenList.length;
    const end = start + tokenList.length;
    // obj[`${keyArr[i]}`] = result
    //   .slice(start, end)
    //   .map((v) => new BigNumber(v[0].toString() || '0').shiftedBy(-tokenList[i].decimals).toFixed(4));
    switch (`${keyArr[i]}`) {
      default:
        obj[`${keyArr[i]}`] = result.slice(start, end).map((v, keyNum) => {
          return {
            number: new BigNumber(v[0].toString() || '0').shiftedBy(-tokenList[keyNum].decimals).toFixed(8),
            localNumber: Number(
              new BigNumber(v[0].toString() || '0')
                .shiftedBy(-tokenList[keyNum].decimals)
                .toFixed(4, BigNumber.ROUND_DOWN),
            ).toLocaleString('en-US', {
              maximumFractionDigits: 4,
            }),
          };
        });
    }
  }
  // console.log({ chain, obj });
  return obj;
};
