import { SvgProps } from 'components/svg/types';
import React from 'react';

const DisconnectIcon: React.FC<SvgProps> = (props) => {
  return (
    <svg viewBox="0 0 16 16" {...props}>
      <path
        d="M10.6666667,1.33333333 C12.139426,1.33333333 13.3333333,2.52724067 13.3333333,4 L13.3333333,12 C13.3333333,13.4727593 12.139426,14.6666667 10.6666667,14.6666667 L6.66666667,14.6666667 C5.19390733,14.6666667 4,13.4727593 4,12 L4,10 L5.33333333,10 L5.33333333,12 C5.33333333,12.7029079 5.87725186,13.2787767 6.56715844,13.3296762 L6.66666667,13.3333333 L10.6666667,13.3333333 C11.3695745,13.3333333 11.9454434,12.7894148 11.9963428,12.0995082 L12,12 L12,4 C12,3.29709214 11.4560815,2.72122325 10.7661749,2.67032382 L10.6666667,2.66666667 L6.66666667,2.66666667 C5.9637588,2.66666667 5.38788992,3.21058519 5.33699049,3.90049177 L5.33333333,4 L5.33333333,6 L4,6 L4,4 C4,2.52724067 5.19390733,1.33333333 6.66666667,1.33333333 L10.6666667,1.33333333 Z M2.66666667,5.33333333 L2.666,7.33333333 L8.66666667,7.33333333 C9.0348565,7.33333333 9.33333333,7.63181017 9.33333333,8 C9.33333333,8.36818983 9.0348565,8.66666667 8.66666667,8.66666667 L2.666,8.66633333 L2.66666667,10.6666667 L1.6328624e-16,8 L2.66666667,5.33333333 Z"
        // fill="#6A6991"
        fillRule="nonzero"
      />
    </svg>
  );
};

export default DisconnectIcon;
