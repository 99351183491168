import { useWeb3React } from '@web3-react/core';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useAppDispatch } from 'state';
import { State } from 'state/types';
import { isErrorDispatch } from 'utils/isErrorDispatch';
import { getChainIdKeyState } from '.';
import { fetchGetProcess } from './actions';
import { GlobalState } from './types';

export const useGlobal = () => {
  const _e = window.ethereum?.networkVersion;
  const [chainId, setChainId] = useState(window.ethereum?.networkVersion || '');
  const { account } = useWeb3React();
  const dispatch = useAppDispatch();
  useEffect(() => {
    setChainId(_e);
  }, [_e]);
  useEffect(() => {
    let interval = null;
    if (dispatch && (account || chainId)) {
      dispatch(fetchGetProcess({ account }));
      dispatch(getChainIdKeyState());
      interval = setInterval(async () => {
        try {
          await isErrorDispatch(dispatch(fetchGetProcess({ account })));
        } catch (e) {
          if (interval) {
            clearInterval(interval);
          }
        }
      }, 20000);
    }
    return () => (interval ? clearInterval(interval) : null);
  }, [dispatch, account, chainId]);
};
export const useGlobalState = (): GlobalState => {
  const global = useSelector((state: State) => state.global);
  // console.log(global);
  return global;
};
