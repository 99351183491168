import { useCallback } from 'react';
import { useHistory } from 'react-router-dom';

export const useJumpPath = (pathName: string, callback?: any) => {
  const history = useHistory();
  const jump = useCallback(
    (e) => {
      e.stopPropagation();
      e.nativeEvent.stopImmediatePropagation();
      history.push(pathName);
      if (callback) callback();
    },
    [history, pathName, callback],
  );
  return { jump };
};
