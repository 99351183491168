import { FC, useState } from 'react';
import styled from 'styled-components';
import { useEffect } from 'react';
import { useMatchBreakpoints } from '@my/ui';
import Header from './Header';
import { useLocation } from 'react-router-dom';
import Page from 'components/Layout/Page';
const Wrapper = styled.div<{ collapsed: boolean }>`
  flex: 1;
  display: flex;
  flex-direction: column;
`;
const BodyContainer = styled.div<{ collapsed: boolean; hidenHeader: boolean }>`
  display: flex;
  flex-direction: column;
  flex: 1;
  transition: 0.15s padding;
  padding-top: ${({ hidenHeader, collapsed }) => (hidenHeader ? '0' : collapsed ? '60px' : '72px')};
  padding-bottom: ${({ hidenHeader, collapsed }) => (hidenHeader ? '0' : collapsed ? '60px' : '0')};
  > .content {
    position: relative;
    flex: 1;
  }
`;
const SideMenu: FC<{ className?: string }> = ({ className, children }) => {
  const [collapsed, setCollapsed] = useState(false);
  const { isXs, isSm, isMd } = useMatchBreakpoints();
  useEffect(() => {
    if ([isXs, isSm, isMd].some(Boolean)) {
      setCollapsed(true);
    } else {
      setCollapsed(false);
    }
  }, [isXs, isSm, isMd]);

  const hidenHeader = window.location.pathname === '/';
  return (
    <Wrapper className={className} collapsed={collapsed}>
      <Page />
      <ScrollTop />
      {/* <Header setCollapsed={setCollapsed} collapsed={collapsed} /> */}
      {hidenHeader ? null : <Header setCollapsed={setCollapsed} collapsed={collapsed} />}
      <BodyContainer collapsed={collapsed} hidenHeader={hidenHeader}>
        <div className="content">
          <div className="bg-holder">{children}</div>
        </div>
      </BodyContainer>
    </Wrapper>
  );
};

const ScrollTop = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
};

export default SideMenu;
