import { InjectedConnector } from '@web3-react/injected-connector';
import { WalletConnectConnector } from '@web3-react/walletconnect-connector';
import { BscConnector } from '@binance-chain/bsc-connector';
import { ConnectorNames } from '@my/ui';
import { ethers } from 'ethers';
import getNodeUrl from './getRpcUrl';
import { UAuthConnector } from '@uauth/web3-react';
import { SubWalletConnector } from '@subwallet/web3-react-subwallet-connector-v6';
import { TalismanConnector } from '@talismn/web3react-v6-connector';
import { getChainIdKey } from 'state/global';
import { CHAINID } from 'config/constants/chain_config';

const POLLING_INTERVAL = 12000;
const chain = getChainIdKey();
const chainId = chain.id;
const { rpcUrl } = getNodeUrl(`${chainId}` as CHAINID);
const supportedChainIds = [
  1, 3, 4, 10, 56, 58, 66, 69, 81, 82, 87, 100, 128, 137, 250, 288, 336, 361, 365, 592, 336, 4_002, 42_161, 43_114,
  421_611, 80_001, 43_114, 43_113,
];
const injected = new InjectedConnector({ supportedChainIds: supportedChainIds });

const walletconnect = new WalletConnectConnector({
  rpc: { [chainId]: rpcUrl },
  bridge: 'https://pancakeswap.bridge.walletconnect.org/',
  qrcode: true,
  // @ts-ignore
  pollingInterval: POLLING_INTERVAL,
});

const UAuthMoralis = new UAuthConnector({
  clientID: '5eb44fe6-f9be-41af-85f8-e730c45a18ca',
  redirectUri: `${window.location.origin}${window.location.pathname}`,
  scope: 'openid wallet',
  postLogoutRedirectUri: 'https://www.avault.network',
  connectors: { injected, walletconnect },
});
const bscConnector = new BscConnector({ supportedChainIds: [Number(chainId)] });
//  const subWallet = new SubWalletConnector({ supportedChainIds: [1, 1287, 1284, 1285, 592, 336] })
const subWallet = new SubWalletConnector({ supportedChainIds: [Number(chainId)] });
const talisman = new TalismanConnector({ supportedChainIds: [Number(chainId)] });

export const simpleRpcProvider = new ethers.providers.JsonRpcProvider(rpcUrl);

export const connectorsByName: { [connectorName in ConnectorNames]: any } = {
  [ConnectorNames.Injected]: injected,
  [ConnectorNames.UAuthMoralis]: UAuthMoralis,
  [ConnectorNames.WalletConnect]: walletconnect,
  [ConnectorNames.BSC]: bscConnector,
  [ConnectorNames.SubWallet]: subWallet,
  [ConnectorNames.Talisman]: talisman,
};

export const getLibrary = (provider): ethers.providers.Web3Provider => {
  const library = new ethers.providers.Web3Provider(provider);
  library.pollingInterval = POLLING_INTERVAL;
  return library;
};

/**
 * BSC Wallet requires a different sign method
 * @see https://docs.binance.org/smart-chain/wallet/wallet_api.html#binancechainbnbsignaddress-string-message-string-promisepublickey-string-signature-string
 */
export const signMessage = async (provider: any, account: string, message: string): Promise<string> => {
  if (window.BinanceChain) {
    const { signature } = await window.BinanceChain.bnbSign(account, message);
    return signature;
  }

  /**
   * Wallet Connect does not sign the message correctly unless you use their method
   * @see https://github.com/WalletConnect/walletconnect-monorepo/issues/462
   */
  if (provider.provider?.wc) {
    const wcMessage = ethers.utils.hexlify(ethers.utils.toUtf8Bytes(message));
    const signature = await provider.provider?.wc.signPersonalMessage([wcMessage, account]);
    return signature;
  }

  return provider.getSigner(account).signMessage(message);
};
