import { BASE_URL } from 'config';
import { CHAINID } from 'config/constants/chain_config';
import {
  createTokenPricesKey,
  tokenPriceLifiType,
  tokenPriceLifiTypeMap,
  tokenPricesSearchParamKey,
} from 'lifiConfig/getTokenConfig';
import Tokens from 'lifiConfig/tokens.json';

export async function fetchTokens(chains: CHAINID[], tokenAddress: string[]) {
  const _url = new URL(`${BASE_URL}/api/kv/v0/tokenPrice`);
  for (const _index in chains) {
    _url.searchParams.append(tokenPricesSearchParamKey, createTokenPricesKey(chains[_index], tokenAddress[_index]));
  }
  const _res = await fetch(_url.toString());
  const _rf = (await _res.json()).data as tokenPriceLifiTypeMap;
  // console.log(`fetchTokens: ${JSON.stringify(_rf)}`);
  return _rf;
}

export const fetchToken = async (
  chain: CHAINID,
  tokenAddress: string,
  _forceUpdate: boolean,
): Promise<tokenPriceLifiType> => {
  try {
    if (_forceUpdate) {
      const res = await fetchFromLifi(chain, tokenAddress);
      const r: tokenPriceLifiType = await res.json();
      return r;
    }
  } catch (e) {
    console.error(`fetch price failed: ${e}`);
  }

  try {
    const TokensObj = Tokens.tokens[chain].find((v) => `${v.address.toLowerCase()}` === tokenAddress.toLowerCase());
    if (TokensObj) {
      return TokensObj;
    } else {
      const res = await fetchFromLifi(chain, tokenAddress);
      const r = await res.json();
      return r;
    }
  } catch {
    return {
      address: tokenAddress,
      symbol: '',
      decimals: 18,
      chainId: Number(chain),
      name: '',
      coinKey: '',
      priceUSD: '0',
      logoURI: '',
    };
  }
};

async function fetchFromLifi(chain: CHAINID, tokenAddress: string) {
  return await fetch(`https://li.quest/v1/token?chain=${chain}&token=${tokenAddress}`, {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  });
}

export async function fetchGasPrice() {
  try {
    const apiUrl = `${BASE_URL}/api/kv/v0/gasPrice`;
    const r = await fetch(apiUrl);
    const body = await r.json();
    return body.data as gasPriceType;
  } catch (e: any) {
    return {};
  }
}

export interface gasPriceType {
  [chainId: string]: {
    lastBaseFeePerGas: string;
    maxFeePerGas: string;
    maxPriorityFeePerGas: string;
    gasPrice: string;
  };
}
