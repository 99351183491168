import { SvgProps } from 'components/svg/types';
import React from 'react';

const CopyIcon: React.FC<SvgProps> = (props) => {
  return (
    <svg viewBox="0 0 16 16" {...props}>
      <path
        d="M8.66666667,1.33333333 C9.75714495,1.33333333 10.725321,1.85697125 11.3334655,2.66651759 L4.66666667,2.66666667 C3.60154608,2.66666667 2.73089275,3.49927998 2.6700618,4.54915147 L2.66666667,4.66666667 L2.66666667,8.66666667 C2.66666667,9.53742218 3.22313219,10.2782082 3.99984738,10.5528089 L4,7.33333333 C4,5.49238417 5.49238417,4 7.33333333,4 L11.3333333,4 C13.1742825,4 14.6666667,5.49238417 14.6666667,7.33333333 L14.6666667,11.3333333 C14.6666667,13.1742825 13.1742825,14.6666667 11.3333333,14.6666667 L7.33333333,14.6666667 C5.70102507,14.6666667 4.34275087,13.4933874 4.05584147,11.9441596 C2.50691682,11.6576013 1.33333333,10.2991865 1.33333333,8.66666667 L1.33333333,4.66666667 C1.33333333,2.8257175 2.8257175,1.33333333 4.66666667,1.33333333 L8.66666667,1.33333333 Z M11.3333333,5.33333333 L7.33333333,5.33333333 C6.26821274,5.33333333 5.39755941,6.16594664 5.33672846,7.21581814 L5.33333333,7.33333333 L5.33333333,11.3333333 C5.33333333,12.3984539 6.16594664,13.2691073 7.21581814,13.3299382 L7.33333333,13.3333333 L11.3333333,13.3333333 C12.3984539,13.3333333 13.2691073,12.50072 13.3299382,11.4508485 L13.3333333,11.3333333 L13.3333333,7.33333333 C13.3333333,6.26821274 12.50072,5.39755941 11.4508485,5.33672846 L11.3333333,5.33333333 Z"
        fillRule="nonzero"
      />
    </svg>
  );
};

export default CopyIcon;
