import { useCallback, useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, AppState } from 'state';
import { ThemeContext } from 'styled-components';
import { toggleThemeAction } from '.';

export function useThemeManager(): [boolean, () => void] {
  const dispatch = useDispatch<AppDispatch>();
  const isDark = useSelector<AppState, AppState['user']['isDark']>((state) => state.user.isDark);
  const toggleTheme = useCallback(() => {
    dispatch(toggleThemeAction());
  }, [dispatch]);
  return [isDark, toggleTheme];
}
export const useThemeContext = () => {
  const theme = useContext(ThemeContext);
  return theme;
};
