import { STATIC_BASE_URL } from 'config';
import { CHAINID } from 'config/constants/chain_config';
import { ZERO_ADDRESS } from 'state/safeContract/utils/utils';
import { VaultMatadateType } from 'state/types';
import { IABIType, IDAppName, IVaultType } from 'state/vault/types';
const STGRewardToken = {
  [CHAINID.BSC_MAINNET]: {
    address: '0xb0d502e938ed5f4df2e681fe6e419ff29631d62b',
    chainId: 56,
    symbol: 'STG',
    decimals: 18,
    name: 'Stargate Finance',
    priceUSD: '0.46769000334947497',
    logoURI:
      'https://static.debank.com/image/bsc_token/logo_url/0xb0d502e938ed5f4df2e681fe6e419ff29631d62b/55886c6280173254776780fd8340cca7.png',
    coinKey: 'STG',
    fnname: 'pendingStargate',
  },
  [CHAINID.POLYGON_MAINNET]: {
    address: '0x2f6f07cdcf3588944bf4c42ac74ff24bf56e7590',
    chainId: 137,
    symbol: 'STG',
    decimals: 18,
    name: 'Stargate Finance',
    priceUSD: '0.4533967704574833',
    logoURI:
      'https://static.debank.com/image/matic_token/logo_url/0x2f6f07cdcf3588944bf4c42ac74ff24bf56e7590/55886c6280173254776780fd8340cca7.png',
    coinKey: 'STG',
    fnname: 'pendingStargate',
  },
  [CHAINID.FANTOM_MAINNET]: {
    address: '0x2f6f07cdcf3588944bf4c42ac74ff24bf56e7590',
    chainId: 250,
    symbol: 'STG',
    decimals: 18,
    name: 'StargateToken',
    priceUSD: '0.4421581933367969',
    logoURI:
      'https://static.debank.com/image/ftm_token/logo_url/0x2f6f07cdcf3588944bf4c42ac74ff24bf56e7590/55886c6280173254776780fd8340cca7.png',
    coinKey: 'STG',
    fnname: 'pendingStargate',
  },
  [CHAINID.ARBITRUM_MAINNET]: {
    address: '0x6694340fc020c5e6b96567843da2df01b2ce1eb6',
    chainId: 42161,
    symbol: 'STG',
    decimals: 18,
    name: 'Stargate Finance',
    priceUSD: '0.46769000334947497',
    logoURI:
      'https://static.debank.com/image/arb_token/logo_url/0x6694340fc020c5e6b96567843da2df01b2ce1eb6/3e78cc5b8a3f077a619a7ce556b41b25.png',
    coinKey: 'STG',
    fnname: 'pendingStargate',
  },
  [CHAINID.AVALANCH_MAINNET]: {
    address: '0x2f6f07cdcf3588944bf4c42ac74ff24bf56e7590',
    chainId: 43114,
    symbol: 'STG',
    decimals: 18,
    name: 'Stargate Finance',
    priceUSD: '0.45158847250844186',
    logoURI:
      'https://static.debank.com/image/avax_token/logo_url/0x2f6f07cdcf3588944bf4c42ac74ff24bf56e7590/3e78cc5b8a3f077a619a7ce556b41b25.png',
    coinKey: 'STG',
    fnname: 'pendingStargate',
  },
  [CHAINID.OPTIMISTIC_MAINNET]: {
    address: '0x4200000000000000000000000000000000000042',
    chainId: 10,
    symbol: 'OP',
    decimals: 18,
    name: 'Optimism',
    priceUSD: '1.716419',
    logoURI:
      'https://static.debank.com/image/op_token/logo_url/0x4200000000000000000000000000000000000042/95a6d2edd6dac44e08dd277bb10fbfe9.png',
    coinKey: 'OP',
    fnname: 'pendingEmissionToken',
  },
  [CHAINID.ETH_MAINNET]: {
    address: '0xaf5191b0de278c7286d6c7cc6ab6bb8a73ba2cd6',
    chainId: 1,
    symbol: 'STG',
    decimals: 18,
    name: 'Stargate Finance',
    priceUSD: '0.46867799318656306',
    logoURI:
      'https://static.debank.com/image/eth_token/logo_url/0xaf5191b0de278c7286d6c7cc6ab6bb8a73ba2cd6/55886c6280173254776780fd8340cca7.png',
    coinKey: 'STG',
    fnname: 'pendingStargate',
  },
};

const Lifi = {
  [CHAINID.ARBITRUM_MAINNET]: {
    address: ZERO_ADDRESS,
    chainId: 42161,
    symbol: 'LIFI',
    decimals: 18,
    name: 'LIFI',
    logoURI: `${STATIC_BASE_URL}/images/dappv2/Lifi.svg`,
  },
};

const ARB = {
  [CHAINID.ARBITRUM_MAINNET]: {
    address: ZERO_ADDRESS,
    chainId: 42161,
    symbol: 'ARB',
    decimals: 18,
    name: 'ARB',
    logoURI: `${STATIC_BASE_URL}/images/chain/arbitrum.svg`,
  },
};

const LayerZero = {
  [CHAINID.ARBITRUM_MAINNET]: {
    address: ZERO_ADDRESS,
    chainId: 42161,
    symbol: 'Lz',
    decimals: 18,
    name: 'Lz',
    logoURI: `${STATIC_BASE_URL}/images/chain/layerzero.png`,
  },
};
export const vault_base_config: VaultMatadateType = {
  [CHAINID.ARBITRUM_MAINNET]: {
    status: 1,
    chain: CHAINID.ARBITRUM_MAINNET,
    dapp: [
      {
        dappname: IDAppName.AAVE,
        contract: [
          {
            vaulttype: [IVaultType.single, IVaultType.compound],
            eventtype: 0,
            chainkey: CHAINID.ARBITRUM_MAINNET,
            dappname: IDAppName.AAVE,
            abitype: IABIType.AVaultForAAVE,
            contractAddress: '0x603b5383d1f701c120d4a8e24f2daccef30b80b4',
            vault: {
              vaultsymbol: 'ETH',
              vaultlogoURI:
                STATIC_BASE_URL +
                '/images/tokens/' +
                CHAINID.ARBITRUM_MAINNET +
                '/' +
                '0x603b5383d1f701c120d4a8e24f2daccef30b80b4.svg',
              wantaddresssymbol: 'ETH',
              name: 'Avault Aave ETH',
              wantaddress: '0x82af49447d8a07e3bd95bd0d56f35241523fbab1',
              wantaddressdecimals: 18,
              earnedaddress: '-',
              earnedaddresssymbol: [
                {
                  symbol: '-',
                },
              ],
              expected_token: {
                '0': [ARB[CHAINID.ARBITRUM_MAINNET], Lifi[CHAINID.ARBITRUM_MAINNET]],
              },
              avaaddress: '-',
              decimals: 18,
            },
          },
          {
            vaulttype: [IVaultType.stablecoin, IVaultType.compound],
            eventtype: 0,
            chainkey: CHAINID.ARBITRUM_MAINNET,
            dappname: IDAppName.AAVE,
            abitype: IABIType.AVaultForAAVE,
            contractAddress: '0x99c192d657d3827a8bde853aecda24cd41d53eb9',
            vault: {
              vaultsymbol: 'USDC',
              vaultlogoURI:
                STATIC_BASE_URL +
                '/images/tokens/' +
                CHAINID.ARBITRUM_MAINNET +
                '/' +
                '0x99c192d657d3827a8bde853aecda24cd41d53eb9.svg',
              wantaddresssymbol: 'USDC',
              name: 'Avault Aave USDC',
              wantaddress: '0xff970a61a04b1ca14834a43f5de4533ebddb5cc8',
              wantaddressdecimals: 6,
              earnedaddress: '-',
              earnedaddresssymbol: [
                {
                  symbol: '-',
                },
              ],
              expected_token: {
                '0': [ARB[CHAINID.ARBITRUM_MAINNET], Lifi[CHAINID.ARBITRUM_MAINNET]],
              },
              avaaddress: '-',
              decimals: 6,
            },
          },
        ],
      },
      {
        dappname: IDAppName.STARGATE,
        contract: [
          // USDC
          {
            vaulttype: [IVaultType.stake],
            eventtype: 0,
            chainkey: CHAINID.ARBITRUM_MAINNET,
            dappname: IDAppName.STARGATE,
            abitype: IABIType.AVaultForStargate,
            // pooladdress
            contractAddress: '0x892785f33cdee22a30aef750f285e18c18040c3e',
            stake: {
              amountLPtoLDFromNumber: '1000000000000000000',
              amountLPtoLD: '1001609294577810649',
              chainId: 110,
              routerAddress: '0x53Bf833A5d6c4ddA888F69c22C88C9f356a41614',
              routerEthAddress: '0xbf22f0f184bCcbeA268dF387a49fF5238dD23E40',
              pooladdress: '0x892785f33cdee22a30aef750f285e18c18040c3e',
              poolid: 1,
              pid: 0, // pid !== poolid
              lpstaking: '0xeA8DfEE1898a7e0a59f7527F076106d7e44c2176',
              bridge: '0x352d8275AAE3e0c2404d9f68f6cEE084B5bEB3DD',
              stakesymbol: 'USDC',
              stakelogoURI:
                STATIC_BASE_URL +
                '/images/tokens/' +
                CHAINID.ARBITRUM_MAINNET +
                '/' +
                '0xff970a61a04b1ca14834a43f5de4533ebddb5cc8.svg',
              name: 'Avault Stargate USDC',
              wantaddresssymbol: 'USDC',
              wantaddress: '0xff970a61a04b1ca14834a43f5de4533ebddb5cc8',
              wantaddressdecimals: 6,
              expected_token: {
                '0': [ARB[CHAINID.ARBITRUM_MAINNET], Lifi[CHAINID.ARBITRUM_MAINNET]],
                '1': [LayerZero[CHAINID.ARBITRUM_MAINNET], Lifi[CHAINID.ARBITRUM_MAINNET]],
              },
              earnedaddresssymbol: [STGRewardToken[CHAINID.ARBITRUM_MAINNET]],
            },
          },
          // USDT
          {
            vaulttype: [IVaultType.stake],
            eventtype: 0,
            chainkey: CHAINID.ARBITRUM_MAINNET,
            dappname: IDAppName.STARGATE,
            abitype: IABIType.AVaultForStargate,
            // pooladdress
            contractAddress: '0xb6cfcf89a7b22988bfc96632ac2a9d6dab60d641',
            stake: {
              amountLPtoLDFromNumber: '1000000000000000000',
              amountLPtoLD: '1002040131565354959',
              chainId: 110,
              routerAddress: '0x53Bf833A5d6c4ddA888F69c22C88C9f356a41614',
              routerEthAddress: '0xbf22f0f184bCcbeA268dF387a49fF5238dD23E40',
              pooladdress: '0xb6cfcf89a7b22988bfc96632ac2a9d6dab60d641',
              poolid: 2,
              pid: 1, // pid !== poolid
              lpstaking: '0xeA8DfEE1898a7e0a59f7527F076106d7e44c2176',
              bridge: '0x352d8275AAE3e0c2404d9f68f6cEE084B5bEB3DD',
              stakesymbol: 'USDT',
              stakelogoURI:
                STATIC_BASE_URL +
                '/images/tokens/' +
                CHAINID.ARBITRUM_MAINNET +
                '/' +
                '0xFd086bC7CD5C481DCC9C85ebE478A1C0b69FCbb9.svg',
              name: 'Avault Stargate USDT',
              wantaddresssymbol: 'USDT',
              wantaddress: '0xFd086bC7CD5C481DCC9C85ebE478A1C0b69FCbb9',
              wantaddressdecimals: 6,
              expected_token: {
                '0': [ARB[CHAINID.ARBITRUM_MAINNET], Lifi[CHAINID.ARBITRUM_MAINNET]],
                '1': [LayerZero[CHAINID.ARBITRUM_MAINNET], Lifi[CHAINID.ARBITRUM_MAINNET]],
              },
              earnedaddresssymbol: [STGRewardToken[CHAINID.ARBITRUM_MAINNET]],
            },
          },
          // ETH
          {
            vaulttype: [IVaultType.stake],
            eventtype: 0,
            chainkey: CHAINID.ARBITRUM_MAINNET,
            dappname: IDAppName.STARGATE,
            abitype: IABIType.AVaultForStargate,
            // pooladdress
            contractAddress: '0x915a55e36a01285a14f05de6e81ed9ce89772f8e',
            stake: {
              amountLPtoLDFromNumber: '1000000000000000000',
              amountLPtoLD: '1000709318516513992',
              chainId: 110,
              routerAddress: '0x53Bf833A5d6c4ddA888F69c22C88C9f356a41614',
              routerEthAddress: '0xbf22f0f184bCcbeA268dF387a49fF5238dD23E40',
              pooladdress: '0x915a55e36a01285a14f05de6e81ed9ce89772f8e',
              poolid: 13,
              pid: 2, // pid !== poolid
              lpstaking: '0xeA8DfEE1898a7e0a59f7527F076106d7e44c2176',
              bridge: '0x352d8275AAE3e0c2404d9f68f6cEE084B5bEB3DD',
              stakesymbol: 'ETH',
              stakelogoURI:
                STATIC_BASE_URL +
                '/images/tokens/' +
                CHAINID.ARBITRUM_MAINNET +
                '/' +
                '0x82cbecf39bee528b5476fe6d1550af59a9db6fc0.svg',
              name: 'Avault Stargate ETH',
              wantaddresssymbol: 'ETH',
              wantaddress: ZERO_ADDRESS,
              wantaddressdecimals: 18,
              expected_token: {
                '0': [ARB[CHAINID.ARBITRUM_MAINNET], Lifi[CHAINID.ARBITRUM_MAINNET]],
                '1': [LayerZero[CHAINID.ARBITRUM_MAINNET], Lifi[CHAINID.ARBITRUM_MAINNET]],
              },
              earnedaddresssymbol: [STGRewardToken[CHAINID.ARBITRUM_MAINNET]],
            },
          },
        ],
      },
    ],
  },
  [CHAINID.OPTIMISTIC_MAINNET]: {
    status: 1,
    chain: CHAINID.OPTIMISTIC_MAINNET,
    dapp: [
      {
        dappname: IDAppName.AAVE,
        contract: [
          {
            vaulttype: [IVaultType.stablecoin, IVaultType.compound],
            eventtype: 0,
            chainkey: CHAINID.OPTIMISTIC_MAINNET,
            dappname: IDAppName.AAVE,
            contractAddress: '0x603b5383d1f701c120d4a8e24f2daccef30b80b4',
            abitype: IABIType.AVaultForAAVE,
            vault: {
              name: 'Avault AAVE USDC',
              vaultsymbol: 'USDC',
              vaultlogoURI:
                STATIC_BASE_URL +
                '/images/tokens/' +
                CHAINID.OPTIMISTIC_MAINNET +
                '/' +
                '0x603b5383d1f701c120d4a8e24f2daccef30b80b4.svg',
              wantaddresssymbol: 'USDC',
              wantaddress: '0x7f5c764cbc14f9669b88837ca1490cca17c31607',
              wantaddressdecimals: 6,
              earnedaddress: '-',
              earnedaddresssymbol: [
                {
                  symbol: '-',
                },
              ],
              expected_token: {},
              avaaddress: '-',
              decimals: 6,
            },
          },
          {
            vaulttype: [IVaultType.single, IVaultType.compound],
            eventtype: 0,
            chainkey: CHAINID.OPTIMISTIC_MAINNET,
            dappname: IDAppName.AAVE,
            abitype: IABIType.AVaultForAAVE,
            contractAddress: '0x99c192d657d3827a8bde853aecda24cd41d53eb9',
            vault: {
              name: 'Avault AAVE ETH',
              vaultsymbol: 'ETH',
              vaultlogoURI:
                STATIC_BASE_URL +
                '/images/tokens/' +
                CHAINID.OPTIMISTIC_MAINNET +
                '/' +
                '0x99c192d657d3827a8bde853aecda24cd41d53eb9.svg',
              wantaddresssymbol: 'ETH',
              wantaddress: '0x4200000000000000000000000000000000000006',
              wantaddressdecimals: 18,
              earnedaddress: '-',
              earnedaddresssymbol: [
                {
                  symbol: '-',
                },
              ],
              avaaddress: '-',
              expected_token: {},
              decimals: 18,
            },
          },
        ],
      },
      {
        dappname: IDAppName.STARGATE,
        contract: [
          // USDC
          {
            vaulttype: [IVaultType.stake],
            eventtype: 0,
            chainkey: CHAINID.OPTIMISTIC_MAINNET,
            dappname: IDAppName.STARGATE,
            abitype: IABIType.AVaultForStargate,
            // pooladdress
            contractAddress: '0xdecc0c09c3b5f6e92ef4184125d5648a66e35298',
            stake: {
              // USDC
              amountLPtoLDFromNumber: '1000000000000000000',
              amountLPtoLD: '1001609294577810649',
              chainId: 111,
              routerAddress: '0xB0D502E938ed5f4df2E681fE6E419ff29631d62b',
              routerEthAddress: '0xB49c4e680174E331CB0A7fF3Ab58afC9738d5F8b',
              pooladdress: '0xdecc0c09c3b5f6e92ef4184125d5648a66e35298',
              poolid: 1,
              pid: 0, // pid !== poolid
              lpstaking: '0x4DeA9e918c6289a52cd469cAC652727B7b412Cd2',
              bridge: '0x701a95707A0290AC8B90b3719e8EE5b210360883',
              stakesymbol: 'USDC',
              stakelogoURI:
                STATIC_BASE_URL +
                '/images/tokens/' +
                CHAINID.OPTIMISTIC_MAINNET +
                '/' +
                '0x7f5c764cbc14f9669b88837ca1490cca17c31607.svg',
              name: 'Avault Stargate USDC',
              wantaddresssymbol: 'USDC',
              wantaddress: '0x7f5c764cbc14f9669b88837ca1490cca17c31607',
              wantaddressdecimals: 6,
              expected_token: {
                '1': [LayerZero[CHAINID.ARBITRUM_MAINNET], Lifi[CHAINID.ARBITRUM_MAINNET]],
              },
              earnedaddresssymbol: [STGRewardToken[CHAINID.OPTIMISTIC_MAINNET]],
            },
          },
          // ETH
          {
            vaulttype: [IVaultType.stake],
            eventtype: 0,
            chainkey: CHAINID.OPTIMISTIC_MAINNET,
            dappname: IDAppName.STARGATE,
            abitype: IABIType.AVaultForStargate,
            // pooladdress
            contractAddress: '0xd22363e3762ca7339569f3d33eade20127d5f98c',
            stake: {
              // ETH
              amountLPtoLDFromNumber: '1000000000000000000',
              amountLPtoLD: '1000079161930987047',
              chainId: 111,
              routerAddress: '0xB0D502E938ed5f4df2E681fE6E419ff29631d62b',
              routerEthAddress: '0xB49c4e680174E331CB0A7fF3Ab58afC9738d5F8b',
              pooladdress: '0xd22363e3762ca7339569f3d33eade20127d5f98c',
              poolid: 13,
              pid: 1, // pid !== poolid
              lpstaking: '0x4DeA9e918c6289a52cd469cAC652727B7b412Cd2',
              bridge: '0x701a95707A0290AC8B90b3719e8EE5b210360883',
              stakesymbol: 'ETH',
              stakelogoURI:
                STATIC_BASE_URL +
                '/images/tokens/' +
                CHAINID.OPTIMISTIC_MAINNET +
                '/' +
                '0xb69c8cbcd90a39d8d3d3ccf0a3e968511c3856a0.svg',
              name: 'Avault Stargate ETH',
              wantaddresssymbol: 'ETH',
              wantaddress: ZERO_ADDRESS,
              wantaddressdecimals: 18,
              expected_token: {
                '1': [LayerZero[CHAINID.ARBITRUM_MAINNET], Lifi[CHAINID.ARBITRUM_MAINNET]],
              },
              earnedaddresssymbol: [STGRewardToken[CHAINID.OPTIMISTIC_MAINNET]],
            },
          },
          // DAI
          {
            vaulttype: [IVaultType.stake],
            eventtype: 0,
            chainkey: CHAINID.OPTIMISTIC_MAINNET,
            dappname: IDAppName.STARGATE,
            abitype: IABIType.AVaultForStargate,
            // pooladdress
            contractAddress: '0x165137624f1f692e69659f944bf69de02874ee27',
            stake: {
              // dai
              amountLPtoLDFromNumber: '1000000000000000000',
              amountLPtoLD: '1000000000000000000000000000000',
              chainId: 111,
              routerAddress: '0xB0D502E938ed5f4df2E681fE6E419ff29631d62b',
              routerEthAddress: '0xB49c4e680174E331CB0A7fF3Ab58afC9738d5F8b',
              pooladdress: '0x165137624f1f692e69659f944bf69de02874ee27',
              poolid: 3,
              pid: 2, // pid !== poolid
              lpstaking: '0x4DeA9e918c6289a52cd469cAC652727B7b412Cd2',
              bridge: '0x701a95707A0290AC8B90b3719e8EE5b210360883',
              stakesymbol: 'DAI',
              stakelogoURI:
                STATIC_BASE_URL +
                '/images/tokens/' +
                CHAINID.OPTIMISTIC_MAINNET +
                '/' +
                '0xDA10009cBd5D07dd0CeCc66161FC93D7c9000da1.svg',
              name: 'Avault Stargate DAI',
              wantaddresssymbol: 'DAI',
              wantaddress: '0xDA10009cBd5D07dd0CeCc66161FC93D7c9000da1',
              wantaddressdecimals: 18,
              expected_token: {
                '1': [LayerZero[CHAINID.ARBITRUM_MAINNET], Lifi[CHAINID.ARBITRUM_MAINNET]],
              },
              earnedaddresssymbol: [STGRewardToken[CHAINID.OPTIMISTIC_MAINNET]],
            },
          },
        ],
      },
    ],
  },
  [CHAINID.BSC_MAINNET]: {
    status: 1,
    chain: CHAINID.BSC_MAINNET,
    dapp: [
      {
        dappname: IDAppName.STARGATE,
        contract: [
          // BUSD
          {
            vaulttype: [IVaultType.stake],
            eventtype: 0,
            chainkey: CHAINID.BSC_MAINNET,
            dappname: IDAppName.STARGATE,
            abitype: IABIType.AVaultForStargate,
            // pooladdress
            contractAddress: '0x98a5737749490856b401db5dc27f522fc314a4e1',
            stake: {
              // decimals: 6, // pool lp
              amountLPtoLDFromNumber: '1000000000000000000',
              amountLPtoLD: '1000677431361299310000000000000',
              chainId: 102,
              routerAddress: '0x4a364f8c717cAAD9A442737Eb7b8A55cc6cf18D8',
              pooladdress: '0x98a5737749490856b401db5dc27f522fc314a4e1',
              poolid: 5,
              pid: 1, // pid !== poolid
              lpstaking: '0x3052A0F6ab15b4AE1df39962d5DdEFacA86DaB47',
              bridge: '0x6694340fc020c5E6B96567843da2df01b2CE1eb6',
              stakesymbol: 'BUSD',
              stakelogoURI:
                STATIC_BASE_URL +
                '/images/tokens/' +
                CHAINID.BSC_MAINNET +
                '/' +
                '0xe9e7cea3dedca5984780bafc599bd69add087d56.svg',
              name: 'Avault Stargate BUSD',
              wantaddresssymbol: 'BUSD',
              wantaddress: '0xe9e7cea3dedca5984780bafc599bd69add087d56',
              wantaddressdecimals: 18,
              expected_token: {
                '1': [LayerZero[CHAINID.ARBITRUM_MAINNET], Lifi[CHAINID.ARBITRUM_MAINNET]],
              },
              earnedaddresssymbol: [STGRewardToken[CHAINID.BSC_MAINNET]],
            },
          },
          // USDT
          {
            vaulttype: [IVaultType.stake],
            eventtype: 0,
            chainkey: CHAINID.BSC_MAINNET,
            dappname: IDAppName.STARGATE,
            abitype: IABIType.AVaultForStargate,
            // pooladdress
            contractAddress: '0x9aa83081aa06af7208dcc7a4cb72c94d057d2cda',
            stake: {
              // decimals: 6, // pool lp
              amountLPtoLDFromNumber: '1000000000000000000',
              amountLPtoLD: '1001387587659167062000000000000',
              chainId: 102,
              routerAddress: '0x4a364f8c717cAAD9A442737Eb7b8A55cc6cf18D8',
              pooladdress: '0x9aa83081aa06af7208dcc7a4cb72c94d057d2cda',
              poolid: 2,
              pid: 0, // pid !== poolid
              lpstaking: '0x3052A0F6ab15b4AE1df39962d5DdEFacA86DaB47',
              bridge: '0x6694340fc020c5E6B96567843da2df01b2CE1eb6',
              stakesymbol: 'USDT',
              stakelogoURI:
                STATIC_BASE_URL +
                '/images/tokens/' +
                CHAINID.BSC_MAINNET +
                '/' +
                '0x55d398326f99059fF775485246999027B3197955.svg',
              name: 'Avault Stargate USDT',
              wantaddresssymbol: 'USDT',
              wantaddress: '0x55d398326f99059fF775485246999027B3197955',
              wantaddressdecimals: 18,
              expected_token: {
                '1': [LayerZero[CHAINID.ARBITRUM_MAINNET], Lifi[CHAINID.ARBITRUM_MAINNET]],
              },
              earnedaddresssymbol: [STGRewardToken[CHAINID.BSC_MAINNET]],
            },
          },
        ],
      },
    ],
  },
  [CHAINID.POLYGON_MAINNET]: {
    status: 1,
    chain: CHAINID.POLYGON_MAINNET,
    dapp: [
      {
        dappname: IDAppName.STARGATE,
        contract: [
          // USDC
          {
            vaulttype: [IVaultType.stake],
            eventtype: 0,
            chainkey: CHAINID.POLYGON_MAINNET,
            dappname: IDAppName.STARGATE,
            abitype: IABIType.AVaultForStargate,
            // pooladdress
            contractAddress: '0x1205f31718499dbf1fca446663b532ef87481fe1',
            stake: {
              // decimals: 6, // pool lp
              amountLPtoLDFromNumber: '1000000000000000000',
              amountLPtoLD: '1000655275360888777',
              chainId: 109,
              routerAddress: '0x45A01E4e04F14f7A4a6702c74187c5F6222033cd',
              pooladdress: '0x1205f31718499dbf1fca446663b532ef87481fe1',
              poolid: 1,
              pid: 0, // pid !== poolid
              lpstaking: '0x8731d54E9D02c286767d56ac03e8037C07e01e98',
              bridge: '0x9d1B1669c73b033DFe47ae5a0164Ab96df25B944',
              stakesymbol: 'USDC',
              stakelogoURI:
                STATIC_BASE_URL +
                '/images/tokens/' +
                CHAINID.POLYGON_MAINNET +
                '/' +
                '0x2791Bca1f2de4661ED88A30C99A7a9449Aa84174.svg',
              name: 'Avault Stargate USDC',
              wantaddresssymbol: 'USDC',
              wantaddress: '0x2791Bca1f2de4661ED88A30C99A7a9449Aa84174',
              wantaddressdecimals: 6,
              expected_token: {
                '1': [LayerZero[CHAINID.ARBITRUM_MAINNET], Lifi[CHAINID.ARBITRUM_MAINNET]],
              },
              earnedaddresssymbol: [STGRewardToken[CHAINID.POLYGON_MAINNET]],
            },
          },
          // USDT
          {
            vaulttype: [IVaultType.stake],
            eventtype: 0,
            chainkey: CHAINID.POLYGON_MAINNET,
            dappname: IDAppName.STARGATE,
            abitype: IABIType.AVaultForStargate,
            // pooladdress
            contractAddress: '0x29e38769f23701a2e4a8ef0492e19da4604be62c',
            stake: {
              // decimals: 6, // pool lp
              amountLPtoLDFromNumber: '1000000000000000000',
              amountLPtoLD: '1000746322770678749',
              chainId: 109,
              routerAddress: '0x45A01E4e04F14f7A4a6702c74187c5F6222033cd',
              pooladdress: '0x29e38769f23701a2e4a8ef0492e19da4604be62c',
              poolid: 2,
              pid: 1, // pid !== poolid
              lpstaking: '0x8731d54E9D02c286767d56ac03e8037C07e01e98',
              bridge: '0x9d1B1669c73b033DFe47ae5a0164Ab96df25B944',
              stakesymbol: 'USDT',
              stakelogoURI:
                STATIC_BASE_URL +
                '/images/tokens/' +
                CHAINID.POLYGON_MAINNET +
                '/' +
                '0xc2132D05D31c914a87C6611C10748AEb04B58e8F.svg',
              name: 'Avault Stargate USDT',
              wantaddresssymbol: 'USDT',
              wantaddress: '0xc2132D05D31c914a87C6611C10748AEb04B58e8F',
              wantaddressdecimals: 6,
              expected_token: {
                '1': [LayerZero[CHAINID.ARBITRUM_MAINNET], Lifi[CHAINID.ARBITRUM_MAINNET]],
              },
              earnedaddresssymbol: [STGRewardToken[CHAINID.POLYGON_MAINNET]],
            },
          },
          // DAI
          {
            vaulttype: [IVaultType.stake],
            eventtype: 0,
            chainkey: CHAINID.POLYGON_MAINNET,
            dappname: IDAppName.STARGATE,
            abitype: IABIType.AVaultForStargate,
            // pooladdress
            contractAddress: '0x1c272232df0bb6225da87f4decd9d37c32f63eea',
            stake: {
              // decimals: 6, // pool lp
              amountLPtoLDFromNumber: '1000000000000000000',
              amountLPtoLD: '1000011804028736860000000000000',
              chainId: 109,
              routerAddress: '0x45A01E4e04F14f7A4a6702c74187c5F6222033cd',
              pooladdress: '0x1c272232df0bb6225da87f4decd9d37c32f63eea',
              poolid: 3,
              pid: 2, // pid !== poolid
              lpstaking: '0x8731d54E9D02c286767d56ac03e8037C07e01e98',
              bridge: '0x9d1B1669c73b033DFe47ae5a0164Ab96df25B944',
              stakesymbol: 'DAI',
              stakelogoURI:
                STATIC_BASE_URL +
                '/images/tokens/' +
                CHAINID.POLYGON_MAINNET +
                '/' +
                '0x8f3Cf7ad23Cd3CaDbD9735AFf958023239c6A063.svg',
              name: 'Avault Stargate DAI',
              wantaddresssymbol: 'DAI',
              wantaddress: '0x8f3Cf7ad23Cd3CaDbD9735AFf958023239c6A063',
              wantaddressdecimals: 18,
              expected_token: {
                '1': [LayerZero[CHAINID.ARBITRUM_MAINNET], Lifi[CHAINID.ARBITRUM_MAINNET]],
              },
              earnedaddresssymbol: [STGRewardToken[CHAINID.POLYGON_MAINNET]],
            },
          },
        ],
      },
    ],
  },
};
