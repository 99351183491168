import { memo, useMemo } from 'react';
import { Helmet } from 'react-helmet-async';
import { useLocation } from 'react-router';
import { DEFAULT_META, getCustomMeta } from 'config/constants/meta';

export const PageMeta = () => {
  const { pathname } = useLocation();
  const { title, description, image } = useMemo(() => {
    const pageMeta = getCustomMeta(pathname) || {};
    return { ...DEFAULT_META, ...pageMeta };
  }, [pathname]);
  return useMemo(
    () => (
      <Helmet>
        <title>{title}</title>
        <meta property="og:title" content={title} />
        <meta property="og:description" content={description} />
        <meta property="og:image" content={image} />
      </Helmet>
    ),
    [title, description, image],
  );
};

export default memo(PageMeta);
