const IPFS_GATEWAY = 'https://cloudflare-ipfs.com';
export const getTokenUrl = (tokenUri: string) => {
  if (!tokenUri) {
    return '';
  }
  if (tokenUri.startsWith('ipfs://')) {
    // return null;
    return `${IPFS_GATEWAY}/ipfs/${tokenUri.replace('ipfs://', '')}`;
  }
  // if (tokenUri.indexOf('aavegotchi.com/images/matokens/ma') > -1) {
  //   return '';
  // }

  return tokenUri;
};
