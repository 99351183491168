import { createSlice } from '@reduxjs/toolkit';
import { SafeContractState } from 'state/types';
import { SafeContractExtraReducers } from './reducer';

const initialState: SafeContractState = {
  safeAddress: null,
};
export const safeContractSlice = createSlice({
  name: 'safeContract',
  initialState: initialState,
  reducers: {
    changeSafeContractStateValue: (state, action) => {
      const key = action.payload.key;
      const value = action.payload.value;
      state[key] = value;
    },
  },
  extraReducers: SafeContractExtraReducers,
});
export const { changeSafeContractStateValue } = safeContractSlice.actions;
export default safeContractSlice.reducer;
