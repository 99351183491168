import { useWeb3React } from '@web3-react/core';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useAppDispatch } from 'state';
import { SafeContractState, State } from 'state/types';
import { useVault } from 'state/vault/hooks';
import { fetchSafeContractAsync } from './reducer';

export const useSafeContract = () => {
  const { account } = useWeb3React();
  const dispatch = useAppDispatch();
  const { multiTokenConfig, chainList } = useVault();
  useEffect(() => {
    if (multiTokenConfig && account) {
      dispatch(fetchSafeContractAsync({ chainList, account }));
    }
  }, [dispatch, multiTokenConfig, chainList, account]);
};
export const useSafeContractState = (): SafeContractState => {
  const safeContract = useSelector((state: State) => state.safeContract);
  // console.log({ safeContract });
  return safeContract;
};
