import { CHAINID } from 'config/constants/chain_config';
import { getWalletConfig } from 'lifiConfig/getWalletConfig';
import { sample } from 'lodash';

export function getLink(
  data: string | number,
  type: 'transaction' | 'token' | 'address' | 'block' | 'countdown',
  chainId: CHAINID | number,
): string {
  const chainObj = getWalletConfig(`${chainId}` as CHAINID);
  const pre = sample(chainObj.metamask.blockExplorerUrls);
  switch (type) {
    case 'transaction': {
      return `${pre}tx/${data}`;
    }
    case 'token': {
      return `${pre}token/${data}`;
    }
    case 'block': {
      return `${pre}block/${data}`;
    }
    case 'countdown': {
      return `${pre}block/countdown/${data}`;
    }
    default: {
      return `${pre}address/${data}`;
    }
  }
}
