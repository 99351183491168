import { BigNumber } from 'ethers';
import * as big from 'bignumber.js';

export interface VaultHistoryState {
  vaultHistoryIsLoading: boolean;
  vaultHistory: AvaultTask[];
}

export interface AvaultTask {
  taskStatus: TaskStatus;
  srcAddress: string; //User's main account address
  createTime: number;
  msg: string;
  subTasks: AvaultSubTask[];
}

export interface AvaultSubTask {
  type: SubTaskType;
  txHash: string; //"0x1234..."
  taskStatus: TaskStatus;
  startTime: number;
  chainId: number;
  userOperation: UserOperation;
  actions: Action[];
}

export interface UserOperation {
  toChainId: number;
  to: string;
  value: BigNumber;
  data: string;
  gasToken: string;
  gasTokenAmount: BigNumber;
  operation: number;
  v: number;
  r: string;
  s: string;
}

export interface Action {
  actionType: ActionType;
  tokenAddress: string;
  amountToken: string | BigNumber | big.BigNumber;
  amountUSD?: string | BigNumber | big.BigNumber;
  vendor?: string | Vendor;
  exInfo?: {
    [x: string]: any;
  };
}

export enum Vendor {
  Stargate_Farm = 'Stargate_Farm',
  Stargate_Pool = 'Stargate_Pool',
  Avault_Aave = 'Avault_Aave',
}

export enum ActionType {
  TRANSFER = 'TRANSFER',
  SWAP = 'SWAP',
  BRIDGE = 'BRIDGE',
  DEPOSIT = 'DEPOSIT',
  WITHDRAW = 'WITHDRAW',
  APPROVE = 'APPROVE',
  CLAIM = 'CLAIM',
}

export enum SubTaskType {
  USERTX = 'USERTX',
  DELEGATETX = 'DELEGATETX',
  BRIDGETOTX = 'BRIDGETOTX',
}

export enum TaskStatus {
  PENDING = 'PENDING',
  PROCESSING = 'PROCESSING',
  FINISHED = 'FINISHED',
  // POPULATED, //exInfo (e.g. bridge info) had been populated
  ERROR = 'ERROR',
}
