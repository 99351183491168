import { STATIC_BASE_URL } from 'config';
import { routePath } from 'config/constants/meta';

export interface IMenu {
  text: string;
  link?: string;
  collapsed?: boolean;
  children?: IMenuDetail[] | undefined;
}
export interface IMenuDetail {
  text: string;
  link: string;
  img: any;
  detail: string;
}

export const ALPPathConfig: IMenuDetail[] = [
  {
    text: 'Sirius Finance',
    img: `${STATIC_BASE_URL}/images/Sirius.svg`,
    link: 'https://app.sirius.finance/#/pools',
    detail: 'Stable swap AMM',
  },
  {
    text: 'Kagla Finance',
    img: `${STATIC_BASE_URL}/images/Kagla.svg`,
    link: 'https://kagla.finance/app/pools',
    detail: 'Stable swap AMM',
  },
];
export const menuItemsDefault: IMenu[] = [
  {
    text: routePath.vault.text,
    link: routePath.vault.path,
  },
  // {
  //   text: routePath.airdrop.text,
  //   link: routePath.airdrop.path,
  // },
  // {
  //   text: 'aLP/aToken',
  //   collapsed: true,
  //   link: '#',
  //   children: ALPPathConfig,
  // },
];
// export const menuItemsDefault = avaultMenuItems;
