import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import { BASE_URL } from 'config';
import { TaskStatus } from 'state/vaultHistory/types';
import { GlobalState } from './types';
export const updateVersion = createAction<void>('global/updateVersion');
export const fetchGetProcess = createAsyncThunk<[][], { account: string }>(
  'global/fetchGetProcess',
  async ({ account }) => {
    if (!account) return null;
    const response = await fetch(BASE_URL + `/api/v0/task?srcAddress=${account}&limit=50`);
    const data = await response.json();
    const taskList = data?.data;
    if (!taskList) {
      return [null, []];
    }
    const process = taskList.filter(
      (v) => v.taskStatus === TaskStatus.PENDING || v.taskStatus === TaskStatus.PROCESSING,
    );
    return [process, taskList];
    // return null;
  },
);
const GlobalExtraReducers = (builder) => {
  builder.addCase(fetchGetProcess.pending, (state: GlobalState, action) => {
    if (!state.multiHistory.length) {
      state.isMultiHistoryLoading = true;
    }
  });
  builder.addCase(fetchGetProcess.fulfilled, (state: GlobalState, action) => {
    if (action.payload) {
      state.isMultiHistoryLoading = false;
      state.processList = action.payload[0];
      state.multiHistory = action.payload[1];
    }
  });
};
export default GlobalExtraReducers;
