import { Flex, useMatchBreakpoints } from '@my/ui';
import DefaultImg from 'components/DefaultImg';
import styled from 'styled-components';
import { TokenPairImage } from 'components/TokenImage';
import { memo } from 'react';
import { IVaultType } from 'state/vault/types';
import { getImageUrlFromToken } from 'utils';
import { IVaultRow } from './MultiTableBody';
import { useDefaultImg } from 'hooks/useDefaultImg';

export const VaultComp = ({ vault, width, height }: { vault: IVaultRow; width?: number; height?: number }) => {
  const { isXs, isSm, isMd } = useMatchBreakpoints();
  const { wantaddresssymbol } = vault;
  return (
    <Flex alignItems="center">
      <TokenWrapperComp vault={vault} width={width} height={height} />
      {[isXs, isSm, isMd].some(Boolean) && wantaddresssymbol.indexOf('LP') > -1 ? null : <p>{wantaddresssymbol}</p>}
    </Flex>
  );
};

export const VaultTextComp = ({
  vault,
  dappname,
  width,
  height,
}: {
  vault: IVaultRow;
  dappname: string;
  width?: number;
  height?: number;
}) => {
  const { isXs, isSm, isMd } = useMatchBreakpoints();
  const { wantaddresssymbol } = vault;
  return (
    <Flex alignItems="center">
      <TokenWrapperComp vault={vault} width={width} height={height} />
      {[isXs, isSm, isMd].some(Boolean) && wantaddresssymbol.indexOf('LP') > -1 ? null : (
        <p className="title_inner_text">
          {wantaddresssymbol}
          <i>{dappname}</i>
        </p>
      )}
    </Flex>
  );
};
export const TokenWrapperComp = memo(
  ({ vault, width, height }: { vault: IVaultRow; width?: number; height?: number }) => {
    const { token0address, token1address, wantaddress, chain, vaulttype } = vault;
    return (
      <TokenWrapper>
        {vaulttype.includes(IVaultType.stake) ||
        vaulttype.includes(IVaultType.stablecoin) ||
        vaulttype.includes(IVaultType.single) ? (
          <img src={getImageUrlFromToken(wantaddress, chain)} className="img" alt="" onError={useDefaultImg} />
        ) : vaulttype.includes(IVaultType.lp_token) ? (
          <TokenPairImage
            variant="inverted"
            primaryToken={token0address}
            secondaryToken={token1address}
            chainkeyFromSource={chain}
            // dappFromSource={dappFromSource}
            width={width ?? 60}
            height={height ?? 60}
          />
        ) : (
          <DefaultImg />
        )}
      </TokenWrapper>
    );
  },
);
const TokenWrapper = styled.div`
  margin-right: 6px;
  // max-width: 22px;
  // min-width: 22px;
  width: 28px;
  margin-right: 10px;
  // ${({ theme }) => theme.mediaQueries.md} {
  //   min-width: 32px;
  // }
  .img {
    display: block;
    //   // width: 80%;
    //   // margin: 0 auto;
    //   // width: 32px;
    //   // min-width: 22px;
  }
  // ${({ theme }) => theme.mediaQueries.sm} {
  //   padding-right: 10px;
  // }
`;
