// Set of helper functions to facilitate wallet setup
import { TalismanConnector } from '@talismn/web3react-v6-connector';
import { STATIC_BASE_URL } from 'config';
import { getWalletConfig } from 'lifiConfig/getWalletConfig';
import { CHAINID } from './constants/chain_config';

export const setupNetwork = async (connector: any, chainId?: CHAINID) => {
  let provider;
  if (connector instanceof TalismanConnector) {
    provider = await connector.getProvider();
  } else {
    provider = window.ethereum;
  }
  if (provider) {
    const chainObj = getWalletConfig(chainId);
    try {
      await provider.request({
        method: 'wallet_switchEthereumChain',
        params: [{ chainId: chainObj.metamask.chainId }],
      });
      return true;
    } catch (switchError) {
      if ((switchError as any)?.code === 4902) {
        try {
          await provider.request({
            method: 'wallet_addEthereumChain',
            params: [
              {
                chainName: chainObj.metamask.chainName,
                chainId: chainObj.metamask.chainId,
                nativeCurrency: chainObj.metamask.nativeCurrency,
                rpcUrls: chainObj.metamask.rpcUrls,
                blockExplorerUrls: chainObj.metamask.blockExplorerUrls,
              },
            ],
          });
          return true;
        } catch (error) {
          console.error('Failed to setup the network in Metamask:', error);
          return false;
        }
      }
      return false;
    }
  } else {
    console.error("Can't setup the BSC network on metamask because window.ethereum is undefined");
    return false;
  }
};

/**
 * Prompt the user to add a custom token to metamask
 * @param tokenAddress
 * @param tokenSymbol
 * @param tokenDecimals
 * @returns {boolean} true if the token has been added, false otherwise
 */
export const registerToken = async (
  tokenAddress: string,
  tokenSymbol: string,
  tokenDecimals: number,
  provider: Window['ethereum'],
  chainkey: CHAINID,
) => {
  const tokenAdded = await provider.request({
    method: 'wallet_watchAsset',
    params: {
      type: 'ERC20',
      options: {
        address: tokenAddress,
        symbol: tokenSymbol,
        decimals: tokenDecimals,
        image: `${STATIC_BASE_URL}/images/tokens/${chainkey}/${tokenAddress}.svg`,
      },
    },
  });

  return tokenAdded;
};
