import { useCallback, useEffect, useMemo, useRef } from 'react';
import styled from 'styled-components';
import ConnectWalletButton from '../../ConnectWalletButton';
import { useWeb3React } from '@web3-react/core';
import useAuth from 'hooks/useAuth';
import { subStringAccount } from 'hooks/useAccount';
import { Flex, useTooltip } from '@my/ui';
import ListIcon from '../imgs/ListIcon';
import DisconnectIcon from '../imgs/DisconnectIcon';
import { routePath } from 'config/constants/meta';
import { CopyButton } from 'components/CopyButton';
import { useJumpPath } from 'hooks/useJumpPath';
import { useGlobalState } from 'state/global/hooks';
import { useThemeContext } from 'state/user/hook';
// import { connectors, walletLocalStorageKey, Flex } from '@my/ui';
const WalletAccountInfo = () => {
  const theme = useThemeContext();
  const { account } = useWeb3React();
  const { chain } = useGlobalState();
  const { logout: _logout } = useAuth({ chain });
  const setMoreTooltipVisible = useRef<React.Dispatch<React.SetStateAction<boolean>>>();

  // const [address, setAddress] = useState<string>(account);
  // const { callAccount } = useAccount();
  const { jump } = useJumpPath(routePath.vault_transaction_list.path, () => {
    setMoreTooltipVisible.current(false);
  });
  // const jump = useCallback(
  //   (e) => {
  //     e.stopPropagation();
  //     e.nativeEvent.stopImmediatePropagation();
  //     setMoreTooltipVisible.current(false);
  //     history.push(`${routePath.vault_transaction_list.path}`);
  //   },
  //   [history, setMoreTooltipVisible],
  // );

  const logout = useCallback(() => {
    setMoreTooltipVisible.current(false);
    _logout();
  }, [_logout]);
  // useEffect(() => {
  // callAccount()
  //   .then((result) => {
  //     setAddress(result.account);
  //   })
  //   .catch(() => {
  //     setAddress(account);
  //   });
  // }, [account]);

  const {
    targetRef: MenuTargetRef,
    tooltip: MenuTooltip,
    tooltipVisible: MenuTooltipVisible,
    setTooltipVisible,
  } = useTooltip(
    <>
      <UserMenuTooltip
        jump={jump}
        account={account}
        setTooltipVisible={setMoreTooltipVisible.current}
        logout={logout}
      />
    </>,
    {
      trigger: 'click',
      tootipStyle: {
        // position: 'fixed',
        // backgroundColor: 'transparent',
        background: theme.isDark ? theme.colors.background02 : theme.colors.background,
        // background: theme.colors.background02,
        // border: '1px solid #2E2D50',
        boxShadow: '2px 12px 16px 0 rgba(0, 0, 0, 0.3)',
        padding: '8px',
        marginTop: '7px',
      },
      // placement: 'bottom-end',
      hideArrow: false,
      arrowBackground: theme.isDark ? theme.colors.background02 : theme.colors.background,
    },
  );
  useEffect(() => {
    setMoreTooltipVisible.current = setTooltipVisible;
  }, [setTooltipVisible]);
  return useMemo(() => {
    return (
      <>
        {/* {MenuTooltip} */}
        {MenuTooltipVisible && MenuTooltip}
        {account && chain ? (
          <WalletAccount ref={MenuTargetRef}>
            <img src={chain.logoURI} alt={chain.name} className="head_icon" />
            <p>{subStringAccount(account)}</p>
          </WalletAccount>
        ) : (
          <ConnectWalletButton scale="sm" />
        )}
      </>
    );
  }, [account, MenuTargetRef, MenuTooltip, MenuTooltipVisible, chain]);
};
const WalletAccount = styled(Flex)`
  align-items: center;
  justify-content: space-between;
  font-size: 14px;
  font-weight: 600;
  color: #fff;
  height: 36px;
  // width: 165px;
  padding: 4px 2px;
  cursor: pointer;

  background-image: ${({ theme }) =>
    `radial-gradient(circle at 50% 0%, ${theme.colors.gradients.a3e255b} 0%,${theme.colors.background} 100%)`};
  border: 1px solid ${({ theme }) => theme.colors.borderColor};
  box-shadow: ${({ theme }) => (theme.isDark ? '0 10px 20px 5px rgba(0, 0, 0, 0.03)' : 'none')};
  border-radius: 11px;
  ${({ theme }) => theme.mediaQueries.md} {
    height: 36px;
  }
  p {
    background-image: ${({ theme }) => theme.colors.gradients.violet02};
    padding: 0 12px;
    height: 32px;
    line-height: 32px;
    border-radius: 8px;
    ${({ theme }) => theme.mediaQueries.md} {
      height: 28px;
      line-height: 28px;
    }
  }
  .head_icon {
    display: block;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    margin-left: 8px;
    margin-right: 12px;
  }
`;

const UserMenuTooltip = ({ jump, account, logout, setTooltipVisible }) => {
  return (
    <UserMenuTooltipStyled>
      <li onClick={jump}>
        <ListIcon />
        <p>Transaction List</p>
      </li>
      <CopyButton
        ParantProps={'li'}
        width="16px"
        buttonColor="textSubtle"
        text={account}
        tooltipMessage="Copy Address Success"
        tooltipTop={10}
        tooltipRight={0}
        tooltipFontSize={12}
      >
        <p>Copy Address</p>
      </CopyButton>
      <li onClick={logout}>
        <DisconnectIcon />
        <p>Disconnect</p>
      </li>
    </UserMenuTooltipStyled>
  );
};
const UserMenuTooltipStyled = styled.ul`
  font-size: 12px;
  color: #6a6991;
  li {
    display: flex;
    align-items: center;
    justify-content: start;
    padding: 8px;
    border-radius: 8px;
    transition: all 0.3s ease;
    cursor: pointer;

    &:hover {
      background: ${({ theme }) => theme.colors.background};
      color: ${({ theme }) => theme.colors.text};
      path {
        fill: ${({ theme }) => theme.colors.text};
      }
      rect {
        stroke: ${({ theme }) => theme.colors.text};
      }
    }
  }
  svg {
    width: 16px;
    margin-right: 4px;
    path {
      fill: #6a6991;
      transition: all 0.3s ease;
    }
    rect {
      transition: all 0.3s ease;
    }
  }
`;
export default WalletAccountInfo;
