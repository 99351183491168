import { Svg, SvgProps } from '@my/ui';
import { FC } from 'react';

const IconLink: FC<SvgProps> = (props) => {
  return (
    <Svg viewBox="0 0 15 15" {...props}>
      <g fill="none" fillRule="evenodd">
        <path
          stroke="#6A6991"
          strokeLinecap="round"
          d="M7.07184255 3.00150863L3.03078966 3.00150863 3.03078966 11.0015086 11.1128954 11.0015086 11.1128954 7.00150863"
          transform="translate(0 .966651)"
        />
        <path
          d="M3.30986925,1.87400292 L3.30986925,0.245535276 C3.30986925,-0.00631697954 3.14747068,-0.0759786906 2.94987311,0.0906738992 L0.149421213,2.50846352 C0.102682116,2.54368007 0.0648536222,2.58916616 0.0389229089,2.64135793 C0.0129924325,2.69352625 -0.000330598946,2.75094658 -2.29707262e-13,2.80909374 C0.000120166065,2.86721746 0.0136386159,2.92456745 0.0395314298,2.97675922 C0.0654240069,3.02895099 0.103008287,3.0746012 0.149421213,3.11023979 L2.94987311,5.53446077 C3.14853659,5.70111805 3.30986925,5.63091941 3.30986925,5.37959705 L3.30986925,3.75004618 L3.78356335,3.75004618 C4.65979558,3.70453665 5.49748894,3.3790063 6.17098351,2.82229409 C6.84447807,2.26560532 7.31743787,1.50774611 7.51894381,0.662434039 C7.54713143,0.542791557 7.56719438,0.421416383 7.57903791,0.299122109 C7.59310804,0.173195981 7.53192432,0.145866814 7.44153445,0.233211828 C7.44153445,0.233211828 7.41554973,0.256789566 7.39442086,0.280367303 C6.43606555,1.24312962 5.14642282,1.81308907 3.78247375,1.87669926 L3.30877965,1.87669926 L3.30986925,1.87400292 Z"
          transform="scale(-1 1) rotate(60 -5.5333714 -7.65077828)"
          fill="#6A6991"
          fillRule="nonzero"
        />
      </g>
    </Svg>
  );
};

export default IconLink;
