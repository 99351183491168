import { CHAINID } from 'config/constants/chain_config';
import { getWalletConfig } from 'lifiConfig/getWalletConfig';
import sample from 'lodash/sample';

const getNodeUrl = (chainId: CHAINID) => {
  try {
    const chainObj = getWalletConfig(chainId);
    return { rpcUrl: sample(chainObj.metamask.rpcUrls), multicallAddress: chainObj.multicallAddress };
  } catch (e) {
    console.log(e, chainId);
  }
};

export default getNodeUrl;
